import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppThunk, RootState } from '../..';
import { Customer, CustomerSearchQuery } from '../../types/customers';
import { getCustomers, searchCustomers } from '../services/api';
import { addNotification, NotificationType } from './notifications.feature';

const initialState: {
  customers: Customer[];
  loading: boolean;
  error: string | null;
  isFiltering: boolean;
  searchQuery: CustomerSearchQuery;
} = {
  customers: [],
  loading: false,
  error: null,
  isFiltering: false,
  searchQuery: {},
};

const customersFeature = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsFiltering: (state, action: PayloadAction<boolean>) => {
      state.isFiltering = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<CustomerSearchQuery>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const searchCustomersWithFilters =
  (query: CustomerSearchQuery): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { data } = await searchCustomers(query);
      dispatch(setCustomers(data));
      console.log(data);
    } catch (e: unknown) {
      const error = e as AxiosError;
      dispatch(
        addNotification({
          type: NotificationType.Error,
          message: `Errore nel caricamento dei clienti: ${
            (error.response?.data as any).message
          }`,
        }),
      );
    } finally {
      dispatch(setLoading(false));
      dispatch(setIsFiltering(false));
    }
  };

export const loadCustomers = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await getCustomers();
    dispatch(setCustomers(data));
  } catch (e) {
    dispatch(setError((e as AxiosError).toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const customersSelector = (state: RootState) => state.customers;

export const { setLoading, setError, setCustomers, setIsFiltering } =
  customersFeature.actions;
export default customersFeature.reducer;
