import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppThunk, RootState } from '../..';
import { Map } from '../../types/map';
import {
  createMap,
  deleteMap,
  getMaps,
  getRestaurant,
  updateMap,
} from '../services/api';
import { addNotification, NotificationType } from './notifications.feature';

export type MapFeatureState = {
  maps: Map[];
  restaurantDefaultMapId?: string;
  isCreatingNewMap: boolean;
  newMap?: Map;
  loading: boolean;
  error: unknown;
  isRenamingMap?: Map;
};

const initialState: MapFeatureState = {
  maps: [],
  loading: false,
  error: null,
  isCreatingNewMap: false,
};

const mapsSlice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setMaps: (state, action) => {
      state.maps = action.payload;
    },
    setRestaurantDefaultMapId: (state, action) => {
      state.restaurantDefaultMapId = action.payload;
    },
    setNewMap: (state, action) => {
      state.newMap = action.payload;
    },
    setIsCreatingNewMap: (state, action) => {
      state.isCreatingNewMap = action.payload;
    },
    setIsRenamingMap: (state, action) => {
      state.isRenamingMap = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const loadMaps = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data: restaurant } = await getRestaurant();
    const { data } = await getMaps();
    dispatch(setMaps(data.filter((m) => m.isActive !== false && !m.isHidden)));
    dispatch(setRestaurantDefaultMapId(restaurant.defaultMapId));
  } catch (error: unknown) {
    dispatch(setError((error as Error).toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateNewMapValue =
  (key: keyof Map, value: string): AppThunk =>
  async (dispatch, getState) => {
    const { newMap } = mapsSelector(getState());
    dispatch(
      setNewMap({
        ...newMap,
        [key]: value,
      }),
    );
  };

export const updateRenamingMapValue =
  (key: keyof Map, value: string): AppThunk =>
  async (dispatch, getState) => {
    const { isRenamingMap } = mapsSelector(getState());
    dispatch(
      setIsRenamingMap({
        ...isRenamingMap,
        [key]: value,
      }),
    );
  };

export const createNewMap = (): AppThunk => async (dispatch, getState) => {
  const { newMap } = mapsSelector(getState());

  if (!newMap?.name) {
    dispatch(
      addNotification({
        message: 'Per creare una nuova mappa è necessario inserire un nome',
        type: NotificationType.Error,
      }),
    );
    return;
  }

  try {
    const { data: restaurant } = await getRestaurant();
    const { data } = await createMap(newMap!, restaurant.id);
    dispatch(loadMaps());
    dispatch(setIsCreatingNewMap(false));
    dispatch(
      addNotification({
        message: 'Mappa creata con successo',
        type: NotificationType.Success,
      }),
    );
  } catch (e: unknown) {
    const error = e as AxiosError;
    dispatch(
      addNotification({
        message:
          'Si è verificato un errore durante la creazione della mappa: ' +
          (error.response?.data as any).message,
        type: NotificationType.Error,
      }),
    );
    dispatch(setError((error as Error).toString()));
  } finally {
  }
};

export const removeMap =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await deleteMap(id);
      dispatch(loadMaps());
      dispatch(
        addNotification({
          message: 'Mappa cancellata con successo',
          type: NotificationType.Success,
        }),
      );
    } catch (e) {
      dispatch(
        addNotification({
          message:
            'Si è verificato un errore durante la cancellazione della mappa: ' +
            ((e as AxiosError).response?.data as any).message,
          type: NotificationType.Error,
        }),
      );
      dispatch(setLoading(false));
    }
  };

export const renameMap = (): AppThunk => async (dispatch, getState) => {
  const { isRenamingMap } = mapsSelector(getState());

  if (!isRenamingMap?.name) {
    dispatch(
      addNotification({
        message: 'Per rinominare una mappa è necessario inserire un nome',
        type: NotificationType.Error,
      }),
    );
    return;
  }

  dispatch(setLoading(true));

  try {
    const { data } = await updateMap({
      name: isRenamingMap.name,
      id: isRenamingMap.id,
    });
    dispatch(loadMaps());
    dispatch(setIsRenamingMap(undefined));
    dispatch(
      addNotification({
        message: 'Mappa rinominata con successo',
        type: NotificationType.Success,
      }),
    );
  } catch (e: unknown) {
    const error = e as AxiosError;
    dispatch(
      addNotification({
        message:
          'Si è verificato un errore durante la modifica della mappa: ' +
          (error.response?.data as any).message,
        type: NotificationType.Error,
      }),
    );
    dispatch(setLoading(false));
  }
};

export const mapsSelector = (state: RootState) => state.maps;

export const {
  setMaps,
  setLoading,
  setError,
  setNewMap,
  setIsCreatingNewMap,
  setRestaurantDefaultMapId,
  setIsRenamingMap,
} = mapsSlice.actions;

export default mapsSlice.reducer;
