import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = () => {
  return (
    <div className="w-100 h-100 loader text-center">
      <CircularProgress color="secondary" />
    </div>
  );
};
