import React, { useEffect } from 'react';
import { Add, Navigation } from '@mui/icons-material';
import { Button, Fab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table } from '../../../types/table';
import { Loader } from '../../components/loader';
import { craftTable } from '../../constants/map-editor';
import {
  addTable,
  loadMap,
  mapSelector,
  removeTable,
  saveMapAndTables,
  setMap,
  setSelectedTable,
  setSelectedTableIndex,
  setSelectedTableIsNew,
  updateTable,
} from '../../features/map.feature';
import { MapDesigner } from '../../modules/map-designer';
import { TableEditor } from '../../modules/table-editor';
import { StackedLayout } from '../../layouts/stacked-layout';

export const MapView = () => {
  const dispatch = useDispatch();
  const { map, loading, selectedTable, selectedTableIsNew } =
    useSelector(mapSelector);
  const { mapId } = useParams();

  useEffect(() => {
    dispatch(loadMap(mapId!));
  }, []);

  const selectTable = (table: Table, index: number) => {
    dispatch(setSelectedTable(table));
    dispatch(setSelectedTableIsNew(false));
    dispatch(setSelectedTableIndex(index));
  };

  return (
    <StackedLayout fullHeight={true}>
      {loading || !map ? (
        <Loader />
      ) : (
        <div id="map-view">
          <div className="container fluid">
            <div className="row">
              <div className="col-12 p-1 flex justify-between items-center">
                <h1 className="font-bold">{map.name}</h1>
                <Button
                  variant="contained"
                  color={'primary'}
                  onClick={() => dispatch(saveMapAndTables())}
                >
                  <Navigation sx={{ mr: 1 }} />
                  Salva
                </Button>
              </div>
            </div>

            <MapDesigner
              map={map}
              onUpdate={(map) => dispatch(setMap(map))}
              onSelect={selectTable}
            />

            <TableEditor
              table={selectedTable}
              onCreate={(table) => dispatch(addTable(table))}
              onUpdate={(table) => dispatch(updateTable(table))}
              onDelete={() => dispatch(removeTable())}
              isNew={selectedTableIsNew}
            />

            <Fab
              color="secondary"
              aria-label="add"
              onClick={() => {
                dispatch(setSelectedTable(craftTable({ mapId: map.id })));
                dispatch(setSelectedTableIsNew(true));
              }}
              style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
            >
              <Add />
            </Fab>
          </div>
        </div>
      )}
    </StackedLayout>
  );
};
