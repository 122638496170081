import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';

export type ConfirmationsFeatureState = {
  isOpen: boolean;
  message: string;
  onConfirm?: () => void;
};

const initialState: ConfirmationsFeatureState = {
  isOpen: false,
  message: '',
};

export const confirmationFeature = createSlice({
  name: 'confirmations',
  initialState,
  reducers: {
    askForConfirmation: (state, action) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.onConfirm = action.payload.onConfirm;
    },
    close: (state) => {
      state.isOpen = false;
      state.message = '';
      state.onConfirm = undefined;
    },
  },
});

export const confirmationModalSelector = (state: RootState) =>
  state.confirmations;

export const { askForConfirmation, close } = confirmationFeature.actions;
export default confirmationFeature.reducer;
