import axios, { Axios, AxiosResponse } from 'axios';
import { store } from '../../';
import { Customer, CustomerSearchQuery } from '../../types/customers';
import { Map } from '../../types/map';
import { Reservation, ReservationSearchQuery } from '../../types/reservation';
import { Restaurant, Timeslot } from '../../types/restaurant';
import { Table } from '../../types/table';
import { ReservationExtended } from '../features/reservation.feature';
import { omit } from '../utils/object.util';

const withAuthentication = <T, D>(
  fn: (instance: Axios) => Promise<AxiosResponse<T, D>>,
) =>
  fn(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${store.getState().user.token}`,
      },
    }),
  );

/**
 * Maps
 */
export const getMaps = (): Promise<AxiosResponse<Map[]>> =>
  withAuthentication((instance: Axios) => instance.get('/maps'));

export const getMap = (id: string) =>
  withAuthentication((instance: Axios) => instance.get('/maps/' + id));

export const createMap = (map: Partial<Map>, restaurantId: string) =>
  withAuthentication((instance: Axios) =>
    instance.post('/maps', { ...map, restaurantId }),
  );

export const updateMap = (map: Partial<Map>) =>
  withAuthentication((instance: Axios) => instance.put('/maps/' + map.id, map));

export const deleteMap = (id: string) =>
  withAuthentication((instance: Axios) => instance.delete('/maps/' + id));

export const createTable = (table: Partial<Table>) =>
  withAuthentication((instance: Axios) => instance.post('/tables', table));

export const updateTables = (tables: Partial<Table>[]) =>
  withAuthentication((instance: Axios) => instance.put('/tables/', tables));

export const deleteTable = (id: string) =>
  withAuthentication((instance: Axios) => instance.delete('/tables/' + id));

/**
 * Customers
 */
export const getCustomers = () =>
  withAuthentication((instance: Axios) => instance.get('/customers'));

export const getCustomer = (id: string) =>
  withAuthentication((instance: Axios) => instance.get('/customers/' + id));

export const searchCustomers = (query: CustomerSearchQuery) =>
  withAuthentication((instance: Axios) =>
    instance.post('/customers/search', query),
  );

export const createCustomer = (
  customer: Omit<Customer, 'id'>,
  restaurantId: string,
) =>
  withAuthentication((instance: Axios) =>
    instance.post('/customers', {
      ...customer,
      restaurantId,
    }),
  );

export const editCustomer = (
  id: string,
  customer: Partial<Omit<Customer, 'reservations'>>,
) =>
  withAuthentication((instance: Axios) =>
    instance.put('/customers/' + id, customer),
  );

export const deleteCustomer = (id: string) =>
  withAuthentication((instance: Axios) => instance.delete('/customers/' + id));

/**
 * Restaurant
 */

export const getRestaurant = () =>
  withAuthentication((instance: Axios) => instance.get('/restaurants/primary'));

export const updateRestaurant = (restaurant: Restaurant) =>
  withAuthentication((instance: Axios) =>
    instance.put(
      '/restaurants/' + restaurant.id,
      omit(['id', 'maps', 'timeslots'], restaurant),
    ),
  );

export const createTimeslot = (timeslot: Timeslot) =>
  withAuthentication((instance: Axios) =>
    instance.post('/timeslots', omit(['reservations'], timeslot)),
  );

export const getTimeslots = () =>
  withAuthentication((instance: Axios) => instance.get('/timeslots'));

export const getTimeslot = (id: string) =>
  withAuthentication((instance: Axios) => instance.get('/timeslots/' + id));

export const updateTimeslot = (timeslot: Timeslot & { id: string }) =>
  withAuthentication((instance: Axios) =>
    instance.put('/timeslots/' + timeslot.id, omit(['reservations'], timeslot)),
  );

export const deleteTimeslot = (id: string) =>
  withAuthentication((instance: Axios) => instance.delete('/timeslots/' + id));

export const createMapException = (
  timeslotId: string,
  mapId: string,
  date: number,
) =>
  withAuthentication((instance: Axios) =>
    instance.post('/timeslots/map-exceptions', {
      timeslotId,
      mapId,
      date,
    }),
  );

/**
 * Reservations
 */

export const createReservation = (
  reservation: ReservationExtended,
  timeslot: Timeslot,
  restaurantId: string,
  customerId: string,
) =>
  withAuthentication((instance: Axios) =>
    instance.post('/reservations', {
      restaurantId: restaurantId,
      customerId,
      tableId: reservation.table?.id,
      timeslotId: timeslot.id,
      date: reservation.date,
      guests: reservation.guests,
      referral: reservation.referral,
      notes: reservation.notes,
    }),
  );

export const deleteReservation = (id: string) =>
  withAuthentication((instance: Axios) =>
    instance.delete('/reservations/' + id),
  );

export const updateReservation = (
  id: string,
  reservation: Partial<Omit<Reservation, 'table' | 'timeslot' | 'customer'>>,
) =>
  withAuthentication((instance: Axios) =>
    instance.put('/reservations/' + id, reservation),
  );

export const getReservation = (id: string) =>
  withAuthentication((instance: Axios) => instance.get('/reservations/' + id));

export const getReservations = () =>
  withAuthentication((instance: Axios) => instance.get('/reservations'));

export const searchReservations = (query: ReservationSearchQuery) =>
  withAuthentication((instance: Axios) =>
    instance.post('/reservations/search', query),
  );
