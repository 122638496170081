import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadTimeslots,
  restaurantSelector,
} from '../../features/restaurant.feature';
import { buildEventsFromTimeslots, CalendarEvent } from './utils';
import { startReservation } from '../../features/reservation.feature';
import { StackedLayout } from '../../layouts/stacked-layout';
import { Skeleton } from '@mui/material';

export const Calendar = () => {
  const [events, setEvents] = React.useState<CalendarEvent[]>([]);
  const dispatch = useDispatch();
  const { timeslots, loadingTimeslots } = useSelector(restaurantSelector);
  const [eventsLoaded, setEventsLoaded] = React.useState(false);

  useEffect(() => {
    dispatch(loadTimeslots());
  }, [dispatch]);

  useEffect(() => {
    setEvents(buildEventsFromTimeslots(timeslots));
    setEventsLoaded(true);
  }, [timeslots]);

  const selectEvent = (event: any) => {
    dispatch(
      startReservation({
        timeslotId: event.event.extendedProps.timeslotId as string,
        event: event.event,
      }),
    );
  };

  return (
    <StackedLayout fullHeight={true}>
      <div className="p-4">
        {!loadingTimeslots && eventsLoaded ? (
          <FullCalendar
            locale={itLocale}
            viewClassNames="bg-white p-1 rounded-[16px] shadow-lg h-auto"
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            firstDay={1}
            height={'100vh'}
            events={events}
            eventClick={selectEvent}
          />
        ) : (
          <>
            <Skeleton animation={'wave'} className="" height={50} />
            <Skeleton animation={'wave'} className="mt-2" height={50} />
            <Skeleton animation={'wave'} className="mt-2" height={50} />
            <Skeleton animation={'wave'} className="mt-2" height={1000} />
          </>
        )}
      </div>
    </StackedLayout>
  );
};
