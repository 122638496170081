import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../..';
import keycloak from '../../keycloak';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    logged: false,
    token: null,
    user: {},
  },
  reducers: {
    login: (state, action) => {
      state.logged = true;
      state.user = action.payload;
    },

    logout: (state, action) => {
      state.logged = false;
      state.user = {};
      state.token = null;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const keycloakLogin = () => keycloak.login();

export const keycloakLogout = () => keycloak.logout();

export const isLoggedSelector = (state: RootState) => state.user.logged;

export const { login, logout, setToken } = userSlice.actions;

export default userSlice.reducer;
