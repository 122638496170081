import { Add } from '@mui/icons-material';
import { Fab, Skeleton } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Reservation } from '../../../types/reservation';
import { Loader } from '../../components/loader';
import { openWithExistingReservation } from '../../features/reservation.feature';
import {
  loadReservations,
  reservationsSelector,
  searchReservationsWithFilters,
  setIsFiltering,
} from '../../features/reservations.feature';
import { SimpleLayout } from '../../layouts/simple-layout';
import DataTable from '../../modules/datatable';
import {
  FilterType,
  SearchFiltersModal,
} from '../../modules/search-filters-modal';
import { sanitizeStringForCSV } from './utils';

export const Reservations = () => {
  const dispatch = useDispatch();
  const { reservations, loading, isFiltering, searchQuery } =
    useSelector(reservationsSelector);
  const navigate = useNavigate();

  const handleExport = () => {
    const cols = [
      'Nome',
      'Cognome',
      'Email',
      'Telefono',
      'Note cliente',
      'Ospiti',
      'Giorno',
      'Ora',
      'Tavolo',
      'Referral',
      'Note prenotazione',
      'Data creazione',
    ];
    const csvContent =
      cols.join(',') +
      '\n' +
      reservations
        .map((e) =>
          [
            sanitizeStringForCSV(String(e.customer.firstName || '')),
            sanitizeStringForCSV(String(e.customer.lastName || '')),
            sanitizeStringForCSV(String(e.customer.email || '')),
            sanitizeStringForCSV(String(e.customer.phone || '')),
            sanitizeStringForCSV(String(e.customer.notes || '')),
            sanitizeStringForCSV(String(e.guests || '')),
            moment(parseInt(e.date)).format('DD/MM/YYYY'),
            e.timeslot.rules.start,
            sanitizeStringForCSV(String(e.table.humanReadableCode || '')),
            sanitizeStringForCSV(String(e.referral || '')),
            sanitizeStringForCSV(String(e.notes || '')),
            moment(e.createdAt).format('DD/MM/YYYY HH:mm'),
          ].join(','),
        )
        .join('\n');

    const blobURL = URL.createObjectURL(
      new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }),
    );

    const blobLink = document.createElement('a');
    blobLink.href = blobURL;
    blobLink.download = `EXPORT_${moment().format('YYYYMMDDTHHmmss')}`;
    blobLink.click();

    URL.revokeObjectURL(blobURL);
  };

  useEffect(() => {
    dispatch(searchReservationsWithFilters(searchQuery));
  }, [dispatch]);

  return (
    <SimpleLayout>
      <h1 className="font-bold">
        {loading ? (
          <Skeleton variant="text" width={200} animation="wave" />
        ) : (
          'Prenotazioni'
        )}
      </h1>

      <div className="mt-2">
        {loading ? (
          <Skeleton variant="rounded" animation="wave" height={500} />
        ) : (
          <DataTable
            defaultOrder={'date'}
            onRowClick={(row) => dispatch(openWithExistingReservation(row))}
            onFilterClick={() => dispatch(setIsFiltering(true))}
            onExportClick={handleExport}
            rows={reservations}
            columns={[
              {
                id: 'firstName',
                name: 'Nome',
                selector: (row: Reservation) => row.customer.firstName,
              },
              {
                id: 'lastName',
                name: 'Cognome',
                selector: (row: Reservation) => row.customer.lastName,
              },
              {
                id: 'phone',
                name: 'Telefono',
                selector: (row: Reservation) => row.customer.phone,
              },
              {
                id: 'table',
                name: 'Tavolo',
                selector: (row: Reservation) =>
                  `${row.table?.humanReadableCode}${
                    !row.table?.isActive ? ' (tavolo eliminato)' : ''
                  }`,
              },
              {
                id: 'guests',
                name: 'Ospiti',
                selector: (row: Reservation) => row.guests,
              },
              {
                id: 'date',
                name: 'Giorno / Ora',
                selector: (row: Reservation) =>
                  moment(parseInt(row.date)).format('DD/MM/YYYY') +
                  ', ' +
                  row.timeslot?.rules?.start,
              },
              {
                id: 'ref',
                name: 'Referral',
                selector: (row: Reservation) => row.referral,
              },
              {
                id: 'notes',
                name: 'Note',
                selector: (row: Reservation) => row.notes,
              },
            ]}
          />
        )}

        <SearchFiltersModal
          open={isFiltering}
          activeQuery={searchQuery}
          onFiltersChanged={(query) =>
            dispatch(searchReservationsWithFilters(query))
          }
          filters={[
            {
              type: FilterType.DATE,
              name: 'fromDate',
              label: 'Dalla data',
            },
            {
              type: FilterType.DATE,
              name: 'toDate',
              label: 'Alla data',
            },
            {
              type: FilterType.TIMESLOT,
              name: 'timeslotId',
              label: 'Slot orario',
            },
            {
              type: FilterType.CUSTOMER,
              name: 'customerId',
              label: 'Cliente',
            },
            {
              type: FilterType.TEXT,
              name: 'tableHumanReadableCode',
              label: 'Codice tavolo',
            },
            {
              type: FilterType.NUMBER,
              name: 'guests',
              label: 'Numero ospiti',
            },
            {
              type: FilterType.TEXT,
              name: 'referral',
              label: 'Referral',
            },
          ]}
        />

        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/')}
          style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
        >
          <Add />
        </Fab>
      </div>
    </SimpleLayout>
  );
};
