import { Table, TableOrientation, TableShape } from '../../../types/table';
import { TABLE_SEAT_SIZE } from '../../constants/map-editor';

export const calculateTableSize = (table: Table) => {
  const dim1 = Math.max(
    TABLE_SEAT_SIZE,
    TABLE_SEAT_SIZE * Math.round((table.capacity - 2) / 2),
  );
  const dim2 = TABLE_SEAT_SIZE;
  const dim3 = Math.max(
    TABLE_SEAT_SIZE / 2,
    (TABLE_SEAT_SIZE / 2) * Math.round((table.capacity - 2) / 2),
  );
  return {
    width: table.orientation === TableOrientation.HORIZONTAL ? dim1 : dim2,
    height: table.orientation === TableOrientation.HORIZONTAL ? dim2 : dim1,
    radius: dim3,
  };
};

export const isSamePoint = (
  point1: { x: number; y: number },
  point2: { x: number; y: number },
  approximate = false,
) => {
  if (approximate) {
    return (
      Math.abs(point1.x - point2.x) < 1 && Math.abs(point1.y - point2.y) < 1
    );
  }

  return point1.x === point2.x && point1.y === point2.y;
};

// export const drawTableOnGraphics = (
//   table: Table,
//   g: PIXI.Graphics,
//   color: number,
// ) => {
//   const tableSize = calculateTableSize(table);
//   g.clear();
//   g.beginFill(color);
//   switch (table.shape) {
//     case TableShape.CIRCLE:
//       g.drawCircle(table.position.x, table.position.y, tableSize.radius);
//       break;
//     case TableShape.RECTANGLE:
//       g.drawRect(
//         table.position.x,
//         table.position.y,
//         tableSize.width,
//         tableSize.height,
//       );
//       break;
//   }
//   g.endFill();
// };
