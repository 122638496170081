import React, { useEffect } from 'react';
import { Add, Delete } from '@mui/icons-material';
import {
  Button,
  Card,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Skeleton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from '../../../types/map';
import { Restaurant, TimeslotType } from '../../../types/restaurant';
import { Loader } from '../../components/loader';
import { loadMaps, mapsSelector } from '../../features/maps.feature';
import {
  addTimeslot,
  removeTimeslot,
  loadRestaurant,
  restaurantSelector,
  setTimeslot,
  saveTimeslots,
  saveRestaurant,
  setRestaurant,
} from '../../features/restaurant.feature';
import { SimpleLayout } from '../../layouts/simple-layout';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export const Settings = () => {
  const dispatch = useDispatch();
  const { restaurant, timeslots, loading, loadingTimeslots } =
    useSelector(restaurantSelector);
  const { maps, loading: isLoadingMaps } = useSelector(mapsSelector);

  useEffect(() => {
    dispatch(loadRestaurant());
    dispatch(loadMaps());
  }, [dispatch]);

  const updateRestaurant = (key: keyof Restaurant, value: string | number) => {
    dispatch(
      setRestaurant({
        ...restaurant,
        [key]: value,
      }),
    );
  };

  const updateTimeslotName = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        name: value,
        index,
      }),
    );
  };

  const updateTimeslotType = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        type: value,
        index,
      }),
    );
  };

  const updateTimeslotStart = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        rules: {
          ...timeslots[index].rules,
          start: value,
        },
        index,
      }),
    );
  };

  const updateTimeslotEnd = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        rules: {
          ...timeslots[index].rules,
          end: value,
        },
        index,
      }),
    );
  };

  const updateTimeslotSpecificDate = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        rules: {
          ...timeslots[index].rules,
          specificDate: value,
        },
        index,
      }),
    );
  };

  const updateTimeslotDays = (
    index: number,
    isChecked: boolean,
    day: number,
  ) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        rules: {
          ...timeslots[index].rules,
          days: isChecked
            ? Array.from(new Set([...(timeslots[index].rules.days || []), day]))
            : timeslots[index].rules.days?.filter((d) => d !== day) || [],
        },
        index,
      }),
    );
  };

  const updateTimeslotCustomMapId = (index: number, value: any) => {
    dispatch(
      setTimeslot({
        ...timeslots[index],
        customMapId: value === 'primary' ? null : value,
        index,
      }),
    );
  };

  return (
    <SimpleLayout>
      <h1 className="font-bold">Impostazioni</h1>
      {!loading && restaurant ? (
        <Card
          className={'mt-2 mx-auto max-w-3xl p-1'}
          style={{ borderRadius: '16px' }}
        >
          <>
            <h2 className={'text-xl font-semibold'}>Generale</h2>
            <div className="flex mt-2">
              <div className="w-100">
                <TextField
                  label="Nome Ristorante"
                  value={restaurant.name}
                  onChange={(e) => updateRestaurant('name', e.target.value)}
                  variant="filled"
                  fullWidth
                />
              </div>
            </div>

            <FormControl className="flex mt-2" variant="filled">
              <InputLabel id="primary_mao">Mappa Primaria</InputLabel>
              <Select
                labelId={'primary_mao'}
                label="Mappa Primaria"
                value={restaurant.defaultMapId}
                onChange={(e) =>
                  updateRestaurant('defaultMapId', e.target.value)
                }
                variant="filled"
                fullWidth
              >
                {maps.map((map: Map) => (
                  <MenuItem key={map.id} value={map.id}>
                    {map.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="mt-2 text-right">
              <Button
                variant="contained"
                className="primary"
                onClick={() => dispatch(saveRestaurant())}
              >
                Salva
              </Button>
            </div>
          </>
        </Card>
      ) : (
        <div className="mt-2 mx-auto max-w-3xl p-2">
          <Skeleton variant="rectangular" />
        </div>
      )}
      <Card
        className="my-3 mx-auto max-w-3xl p-1"
        style={{ borderRadius: '16px' }}
      >
        <h2 className={'text-xl font-semibold'}>Slot prenotabili</h2>
        <div className="mt-2">
          {!loadingTimeslots ? (
            <>
              {timeslots.map((timeslot, i) => (
                <div className="w-100 p-1 bg-slate-100 rounded-lg mb-2">
                  <div className="mb-1 text-right">
                    <Fab
                      onClick={() => dispatch(removeTimeslot(i))}
                      size="small"
                      color="error"
                    >
                      <Delete />
                    </Fab>
                  </div>

                  <TextField
                    label="Nome"
                    value={timeslot.name}
                    onChange={(e) => updateTimeslotName(i, e.target.value)}
                    variant="filled"
                    fullWidth
                  />

                  <FormControl className="mt-1" variant="filled" fullWidth>
                    <InputLabel id="timeslot_type">Tipo</InputLabel>
                    <Select
                      labelId="timeslot_type"
                      label={'Tipo'}
                      value={timeslot.type}
                      onChange={(e) => updateTimeslotType(i, e.target.value)}
                      variant="filled"
                      fullWidth
                    >
                      <MenuItem value={TimeslotType.RECURRENT}>
                        Ricorrente
                      </MenuItem>
                      <MenuItem value={TimeslotType.SINGLE}>
                        Giorno Singolo
                      </MenuItem>
                      <MenuItem value={TimeslotType.CLOSED}>
                        Giorno di chiusura
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {[TimeslotType.SINGLE, TimeslotType.CLOSED].includes(
                    timeslot.type,
                  ) && (
                    <FormControl className="mt-1" variant="filled" fullWidth>
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale="it"
                      >
                        <MobileDatePicker
                          label={'Data'}
                          value={moment(timeslot.rules.specificDate)}
                          onChange={(e) =>
                            e
                              ? updateTimeslotSpecificDate(
                                  i,
                                  e.startOf('day').valueOf(),
                                )
                              : null
                          }
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}

                  {[TimeslotType.SINGLE, TimeslotType.RECURRENT].includes(
                    timeslot.type,
                  ) && (
                    <div className="grid grid-cols-2 gap-3 mt-1">
                      <TextField
                        label="Dalle"
                        value={timeslot.rules.start}
                        onChange={(e) => updateTimeslotStart(i, e.target.value)}
                        variant="filled"
                        fullWidth
                      />
                      <TextField
                        label="Alle"
                        value={timeslot.rules.end}
                        onChange={(e) => updateTimeslotEnd(i, e.target.value)}
                        variant="filled"
                        fullWidth
                      />
                    </div>
                  )}

                  {[TimeslotType.RECURRENT].includes(timeslot.type) && (
                    <div className="w-100 grid grid-cols-3 md:grid-cols-4 gap-0  mt-2">
                      <FormControlLabel
                        label={'Lunedì'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(1)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 1)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Martedì'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(2)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 2)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Mercoledì'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(3)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 3)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Giovedì'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(4)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 4)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Venerdì'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(5)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 5)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Sabato'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(6)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 6)
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'Domenica'}
                        control={
                          <Checkbox
                            checked={timeslot.rules.days?.includes(0)}
                            onChange={(e) =>
                              updateTimeslotDays(i, e.target.checked, 0)
                            }
                          />
                        }
                      />
                    </div>
                  )}

                  <FormControl className="mt-1" variant="filled" fullWidth>
                    <InputLabel id="timeslot_map">Mappa</InputLabel>
                    <Select
                      labelId="timeslot_map"
                      label={'Mappa'}
                      value={timeslot.customMapId || 'primary'}
                      onChange={(e) =>
                        updateTimeslotCustomMapId(i, e.target.value)
                      }
                      variant="filled"
                      fullWidth
                    >
                      <MenuItem value="primary">
                        <i>Primaria</i>
                      </MenuItem>
                      {maps.map((map: Map) => (
                        <MenuItem key={map.id} value={map.id}>
                          {map.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              ))}

              <div className="text-center mt-1 w-100 py-3">
                <Fab
                  onClick={() => dispatch(addTimeslot())}
                  color="primary"
                  size="small"
                >
                  <Add />
                </Fab>
              </div>

              <div className="mt-2 text-right">
                <Button
                  variant="contained"
                  className="primary"
                  onClick={() => dispatch(saveTimeslots())}
                >
                  Salva Slots
                </Button>
              </div>
            </>
          ) : (
            <Skeleton variant="rectangular" height={200} />
          )}
        </div>
      </Card>
    </SimpleLayout>
  );
};
