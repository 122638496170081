import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import userFeature from './app/features/user.feature';
import mapsFeature from './app/features/maps.feature';
import mapFeature from './app/features/map.feature';
import notificationsFeature from './app/features/notifications.feature';
import customersFeature from './app/features/customers.feature';
import customerFeature from './app/features/customer.feature';
import restaurantFeature from './app/features/restaurant.feature';
import reservationFeature from './app/features/reservation.feature';
import './assets/index.css';
import confirmationsFeature from './app/features/confirmations.feature';
import reservationsFeature from './app/features/reservations.feature';

export const store = configureStore({
  reducer: {
    maps: mapsFeature,
    map: mapFeature,
    user: userFeature,
    notifications: notificationsFeature,
    customers: customersFeature,
    customer: customerFeature,
    restaurant: restaurantFeature,
    reservation: reservationFeature,
    reservations: reservationsFeature,
    confirmations: confirmationsFeature,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
