import React, { useEffect } from 'react';
import { Button, Card, Skeleton, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../components/loader';
import {
  customerSelector,
  loadCustomer,
  removeCustomer,
  setCustomerValue,
  updateCustomer,
} from '../../features/customer.feature';
import { SimpleLayout } from '../../layouts/simple-layout';
import { askForConfirmation } from '../../features/confirmations.feature';
import DataTable from '../../modules/datatable';
import { Reservation } from '../../../types/reservation';
import moment from 'moment';
import {
  openWithExistingReservation,
  setReservation,
} from '../../features/reservation.feature';

export const Customer = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { loading, customer } = useSelector(customerSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (customerId) dispatch(loadCustomer(customerId));
  }, [dispatch]);

  const deleteCustomer = () => {
    dispatch(
      askForConfirmation({
        onConfirm: () => {
          dispatch(removeCustomer(customerId!));
          navigate('/customers');
        },
        message:
          'Sei sicuro di voler eliminare questo cliente? Le prenotazioni associate ad esso verranno eliminate.',
      }),
    );
  };

  return (
    <SimpleLayout>
      <div className="md:flex">
        <Card
          className={'p-1 mx-auto md:mx-0 md:w-1/3 mb-2'}
          style={{ borderRadius: '16px' }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="mb-1"
          >
            Dati cliente
          </Typography>
          {!loading && customer ? (
            <>
              <div className="grid grid-cols-1 gap-5">
                <TextField
                  label="Nome"
                  value={customer.firstName}
                  onChange={(e) =>
                    dispatch(setCustomerValue('firstName', e.target.value))
                  }
                  fullWidth
                />
                <TextField
                  label="Cognome"
                  value={customer.lastName}
                  onChange={(e) =>
                    dispatch(setCustomerValue('lastName', e.target.value))
                  }
                  fullWidth
                />
                <TextField
                  label="Telefono"
                  value={customer.phone}
                  onChange={(e) =>
                    dispatch(setCustomerValue('phone', e.target.value))
                  }
                  fullWidth
                />
                <TextField
                  label="Email"
                  value={customer.email}
                  onChange={(e) =>
                    dispatch(setCustomerValue('email', e.target.value))
                  }
                  fullWidth
                />
              </div>
              <div className="mt-2">
                <TextField
                  label="Note"
                  value={customer.notes}
                  onChange={(e) =>
                    dispatch(setCustomerValue('notes', e.target.value))
                  }
                  rows={4}
                  fullWidth
                  multiline
                />
              </div>
              <div className="mt-2 text-right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(updateCustomer())}
                >
                  Salva
                </Button>

                <Button color="error" onClick={deleteCustomer}>
                  Elimina
                </Button>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </Card>

        {customer && !loading ? (
          <div className="md:w-2/3 md:pl-2">
            <DataTable
              title="Prenotazioni"
              columns={[
                {
                  id: 'table',
                  name: 'Tavolo',
                  selector: (row: Reservation) => row.table?.humanReadableCode,
                },
                {
                  id: 'guests',
                  name: 'Numero ospiti',
                  selector: (row: Reservation) => row.guests,
                },
                {
                  id: 'date',
                  name: 'Giorno / Ora',
                  selector: (row: Reservation) =>
                    moment(parseInt(row.date)).format('DD/MM/YYYY') +
                    ', ' +
                    row.timeslot?.rules?.start,
                },
                {
                  id: 'ref',
                  name: 'Referral',
                  selector: (row: Reservation) => row.referral,
                },
              ]}
              rows={customer?.reservations?.filter((r) => r.isActive) || []}
              onRowClick={(row) => dispatch(openWithExistingReservation(row))}
            />
          </div>
        ) : (
          <Skeleton variant="rounded" height="200" />
        )}
      </div>
    </SimpleLayout>
  );
};
