import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Customer } from '../../../types/customers';
import { useDispatch, useSelector } from 'react-redux';
import {
  customersSelector,
  loadCustomers,
} from '../../features/customers.feature';
import { mapsSelector } from '../../features/maps.feature';
import {
  loadTimeslots,
  restaurantSelector,
} from '../../features/restaurant.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  height: '85vh',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  maxHeight: '90vh',
  overflowY: 'scroll',
  p: 4,
};

export enum FilterType {
  TEXT,
  NUMBER,
  DATE,
  CUSTOMER,
  TIMESLOT,
  TABLE,
}

export type Filter<Query> = {
  name: keyof Query;
  label: string;
  type: FilterType;
};

export type IProps<Query> = {
  open: boolean;
  onFiltersChanged: (query: Query) => unknown;
  filters: Filter<Query>[];
  activeQuery: Query;
};

export const SearchFiltersModal = <Query,>({
  open,
  onFiltersChanged,
  filters,
  activeQuery,
}: IProps<Query>) => {
  const [query, setQuery] = React.useState(activeQuery);
  const [autocompleteInputValues, setAutocompleteInputValues] = React.useState<
    Record<string, string>
  >({});
  const dispatch = useDispatch();
  const { customers, loading: isLoadingCustomers } =
    useSelector(customersSelector);
  const { timeslots, loadingTimeslots: isLoadingTimeslots } =
    useSelector(restaurantSelector);

  useEffect(() => {
    if (filters.find((f) => f.type === FilterType.CUSTOMER)) {
      dispatch(loadCustomers());
    }

    if (filters.find((f) => f.type === FilterType.TIMESLOT)) {
      dispatch(loadTimeslots());
    }
  }, [filters, dispatch]);

  const updateFilterData = (key: keyof Query, value: any) => {
    setQuery({
      ...query,
      [key]: value,
    });
  };

  const applyFilters = () => {
    onFiltersChanged(query);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={applyFilters}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <>
        <Fade in={open}>
          <div className="container">
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Cerca
              </Typography>

              {(isLoadingCustomers || isLoadingTimeslots) && (
                <div className="w-100">
                  {filters.map((filter, i) => (
                    <Skeleton key={i} animation="wave" height={100} />
                  ))}
                </div>
              )}

              {!isLoadingCustomers &&
                !isLoadingTimeslots &&
                filters.map((filter) => (
                  <div className="w-100 mt-1">
                    {filter.type === FilterType.TEXT && (
                      <TextField
                        label={filter.label}
                        value={query[filter.name]}
                        onChange={(e) =>
                          updateFilterData(filter.name, e.target.value)
                        }
                        variant={'filled'}
                        fullWidth
                      />
                    )}

                    {filter.type === FilterType.NUMBER && (
                      <TextField
                        label={filter.label}
                        value={query[filter.name]}
                        onChange={(e) =>
                          updateFilterData(
                            filter.name,
                            parseInt(e.target.value || '0') || 0,
                          )
                        }
                        variant={'filled'}
                        fullWidth
                      />
                    )}

                    {filter.type === FilterType.DATE && (
                      <FormControl variant="filled" fullWidth>
                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="it"
                        >
                          <MobileDatePicker
                            label={filter.label}
                            value={moment(query[filter.name] as string)}
                            onChange={(e) =>
                              e
                                ? updateFilterData(filter.name, e.valueOf())
                                : null
                            }
                          />
                        </LocalizationProvider>
                      </FormControl>
                    )}

                    {filter.type === FilterType.CUSTOMER && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customers}
                        value={customers.find(
                          (c) => c.id === query[filter.name],
                        )}
                        onChange={(e: any, value) =>
                          updateFilterData(
                            filter.name,
                            (value as Customer)?.id || undefined,
                          )
                        }
                        getOptionLabel={(option) =>
                          `${option.firstName} ${option.lastName} ${option.phone}`
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.firstName} {option.lastName}{' '}
                            {option.phone ? option.phone : ''}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={
                              autocompleteInputValues[filter.name as string]
                            }
                            onChange={(e) =>
                              setAutocompleteInputValues({
                                ...autocompleteInputValues,
                                [filter.name]: e.target.value,
                              })
                            }
                            label={filter.label}
                            variant="filled"
                            fullWidth
                          />
                        )}
                      />
                    )}

                    {filter.type === FilterType.TIMESLOT && (
                      <FormControl
                        className="mt-1"
                        variant={'filled'}
                        fullWidth
                      >
                        <InputLabel id="timeslot-select">
                          {filter.label}
                        </InputLabel>
                        <Select
                          value={query[filter.name]}
                          labelId="timeslot-select"
                          label={filter.label}
                          variant={'filled'}
                          onChange={(e) =>
                            setQuery({
                              ...query,
                              [filter.name]: e.target.value,
                            })
                          }
                        >
                          {timeslots
                            ?.filter((t) => t.isActive)
                            .map((timeslot, i) => (
                              <MenuItem key={i} value={timeslot.id}>
                                <Chip
                                  label={`${timeslot.rules.start} - ${timeslot.rules.end}`}
                                  className={'mr-1'}
                                />
                                {timeslot.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                ))}

              <div className="w-100 mt-2">
                <Button
                  className="mt-2"
                  variant="contained"
                  color="primary"
                  onClick={applyFilters}
                  fullWidth
                >
                  Cerca
                </Button>
              </div>
            </Box>
          </div>
        </Fade>
      </>
    </Modal>
  );
};
