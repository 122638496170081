
/**
 * Sanitize a string for CSV export
 * 
 * @param str - The string to sanitize
 * @returns A sanitized string
 */
export const sanitizeStringForCSV = (str: string) => {
  return str.replaceAll(
    new RegExp(/([;,"'])/, 'g'),
    ' ',
  );
}