import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import keycloak from '../../../keycloak';
import {
  isLoggedSelector,
  keycloakLogin,
  login,
  setToken,
} from '../../features/user.feature';
import { Login } from '../../pages/login';

export const UserCloack = ({ children }: React.PropsWithChildren<{}>) => {
  const [authenticating, setAuthenticating] = React.useState<boolean>(true);
  const logged = useSelector(isLoggedSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (keycloak.authenticated && logged) ||
      (!keycloak.authenticated && !logged)
    ) {
      setTimeout(() => {
        setAuthenticating(false);
      }, 1500);
    }

    keycloak.onAuthSuccess = () => {
      keycloak.loadUserInfo().then((usr) => {
        dispatch(setToken(keycloak.token));
        dispatch(login(usr));

        keycloak.onTokenExpired = () => keycloakLogin();
      });
    };
  }, [dispatch, logged]);

  if (!logged || authenticating) {
    return <Login authenticating={authenticating} />;
  }

  return <>{children}</>;
};
