/**
 * Application Entry Point
 * You should add application routes here.
 * Point each route to a Page.
 * @class
 * @param {object} props
 */

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import './assets/scss/index.scss';
import './assets/scss/index.scss';
import { Maps } from './app/pages/maps';
import { MapView } from './app/pages/map-view';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { Customers } from './app/pages/customers';
import { Customer } from './app/pages/customer';
import { Settings } from './app/pages/settings';
import { NotificationSystem } from './app/modules/notifications';
import { Calendar } from './app/pages/calendar';
import { Reservation } from './app/modules/reservation';
import { ConfirmationModal } from './app/components/confirmation-modal';
import { Reservations } from './app/pages/reservations';
import { UserCloack } from './app/modules/user-cloak';
import { Register } from './app/pages/register';

const ApplicationEntryPoint = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <ThemeProvider theme={theme}>
        <NotificationSystem />
        <ConfirmationModal />
        <UserCloack>
          <Reservation />
          <BrowserRouter>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/maps/:mapId" element={<MapView />} />
              <Route path="/maps" element={<Maps />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:customerId" element={<Customer />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/reservations" element={<Reservations />} />
              <Route path="/" element={<Calendar />} />
            </Routes>
          </BrowserRouter>
        </UserCloack>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default ApplicationEntryPoint;
