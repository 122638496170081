export enum TableShape {
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
}

export enum TableOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type Table = {
  id: string;
  capacity: number;
  mapId: string;
  humanReadableCode: string;
  position: {
    x: number;
    y: number;
  };
  shape: TableShape;
  orientation: TableOrientation;
  isActive: boolean;
};
