import React, { useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { Fab, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MapCollectionItem } from '../../components/map-collection-item';
import {
  loadMaps,
  mapsSelector,
  setIsCreatingNewMap,
} from '../../features/maps.feature';
import { SimpleLayout } from '../../layouts/simple-layout';
import { CreateMapModal } from '../../modules/create-map-modal';
import { RenameMapModal } from '../../modules/edit-map-modal';

export const Maps = () => {
  const dispatch = useDispatch();
  const { maps, loading, restaurantDefaultMapId } = useSelector(mapsSelector);

  useEffect(() => {
    dispatch(loadMaps());
  }, []);

  return (
    <SimpleLayout>
      <h1 className="font-bold">
        {loading ? (
          <Skeleton variant="text" width={200} animation="wave" />
        ) : (
          'Mappe'
        )}
      </h1>

      <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-5">
        {loading ? (
          <>
            <Skeleton variant="rounded" animation={'wave'} height={200} />
            <Skeleton variant="rounded" animation={'wave'} height={200} />
            <Skeleton variant="rounded" animation={'wave'} height={200} />
            <Skeleton variant="rounded" animation={'wave'} height={200} />
            <Skeleton variant="rounded" animation={'wave'} height={200} />
          </>
        ) : (
          maps
            .filter((m) => !m.isHidden && m.isActive)
            .map((map) => (
              <MapCollectionItem
                map={map}
                isDefault={restaurantDefaultMapId === map.id}
              />
            ))
        )}
      </div>

      <Fab
        color="secondary"
        aria-label="add"
        onClick={() => {
          dispatch(setIsCreatingNewMap(true));
        }}
        style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
      >
        <Add />
      </Fab>

      <CreateMapModal />
      <RenameMapModal />
    </SimpleLayout>
  );
};
