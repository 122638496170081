import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppThunk, RootState } from '../..';
import { Reservation, ReservationSearchQuery } from '../../types/reservation';
import { getReservations, searchReservations } from '../services/api';
import { addNotification, NotificationType } from './notifications.feature';
import moment from 'moment';

export type ReservationsFeatureState = {
  reservations: Reservation[];
  loading: boolean;
  isFiltering: boolean;
  searchQuery: ReservationSearchQuery;
};

const initialState: ReservationsFeatureState = {
  reservations: [],
  loading: false,
  isFiltering: false,
  searchQuery: {
    fromDate: moment().startOf('day').valueOf() as any,
    toDate: moment().endOf('day').add(2, 'week').valueOf() as any,
  },
};

export const reservationsFeature = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setReservations: (state, action: PayloadAction<Reservation[]>) => {
      state.reservations = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsFiltering: (state, action: PayloadAction<boolean>) => {
      state.isFiltering = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<ReservationSearchQuery>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const searchReservationsWithFilters =
  (query: ReservationSearchQuery): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { data } = await searchReservations(query);
      dispatch(setReservations(data));
      console.log(data);
    } catch (e: unknown) {
      const error = e as AxiosError;
      dispatch(
        addNotification({
          type: NotificationType.Error,
          message: `Errore nel caricamento delle prenotazioni: ${
            (error.response?.data as any).message
          }`,
        }),
      );
    } finally {
      dispatch(setLoading(false));
      dispatch(setIsFiltering(false));
    }
  };

export const loadReservations = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const { data } = await getReservations();
    dispatch(
      setReservations(data.filter((d: Reservation) => d.isActive !== false)),
    );
  } catch (e: unknown) {
    const error = e as AxiosError;
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: `Errore nel caricamento delle prenotazioni: ${
          (error.response?.data as any).message
        }`,
      }),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const reservationsSelector = (state: RootState) => state.reservations;

export const { setReservations, setLoading, setIsFiltering } =
  reservationsFeature.actions;
export default reservationsFeature.reducer;
