import React from 'react';
import { Circle, Rect, Text } from 'react-konva';
import { Table, TableShape } from '../../../types/table';
import { calculateTableSize } from './utils';
import { KonvaEventObject } from 'konva/lib/Node';
import { Reservation } from '../../../types/reservation';

export type IProps = {
  table: Table;
  drawMode: boolean;
  onTableDragStart: (event: KonvaEventObject<DragEvent>) => void;
  onTableDragEnd: (event: KonvaEventObject<DragEvent>) => void;
  onTableDragMove: (event: KonvaEventObject<DragEvent>) => void;
  onSelect: () => void;
  reservedTables?: Record<string, Reservation>;
};

export const TableSprite: React.FunctionComponent<IProps> = ({
  table,
  onTableDragStart,
  onTableDragEnd,
  onTableDragMove,
  onSelect,
  reservedTables,
  drawMode,
}: IProps) => {
  const isTableReserved = reservedTables?.[table.id]?.id;
  const tableColor = isTableReserved ? '#f44336' : '#66bb6a';

  return (
    <>
      {table.shape === TableShape.RECTANGLE ? (
        <Rect
          key={table.id}
          id={table.id}
          fill={drawMode ? '#29b6f6' : tableColor}
          position={table.position}
          width={calculateTableSize(table).width}
          height={calculateTableSize(table).height}
          onDragStart={onTableDragStart}
          onDragEnd={onTableDragEnd}
          onDragMove={onTableDragMove}
          onClick={onSelect}
          onTap={onSelect}
          draggable={drawMode}
        />
      ) : (
        <Circle
          key={table.id}
          id={table.id}
          fill={drawMode ? '#29b6f6' : tableColor}
          position={table.position}
          width={calculateTableSize(table).width}
          height={calculateTableSize(table).height}
          radius={calculateTableSize(table).radius}
          onDragStart={onTableDragStart}
          onDragEnd={onTableDragEnd}
          onDragMove={onTableDragMove}
          onClick={onSelect}
          onTap={onSelect}
          draggable={drawMode}
        />
      )}

      <Text
        text={'Tav. ' + table.humanReadableCode}
        position={{
          x:
            table.shape === TableShape.CIRCLE
              ? table.position.x - calculateTableSize(table).radius
              : table.position.x,
          y:
            table.shape === TableShape.CIRCLE
              ? table.position.y - calculateTableSize(table).radius - 10 - 24
              : table.position.y - 10 - 24,
        }}
        fill="#000"
        fontSize={24}
        fontFamily="Inter Tight"
        fontStyle='bold'
        bold={true}
      />

      {isTableReserved && (
        <Text
          text={`${reservedTables?.[table.id].customer.firstName || ''} ${
            reservedTables?.[table.id].customer.lastName || ''
          }, ${reservedTables?.[table.id].guests}`}
          position={{
            x:
              table.shape === TableShape.CIRCLE
                ? table.position.x + calculateTableSize(table).radius
                : table.position.x,
            y:
              table.shape === TableShape.CIRCLE
                ? table.position.y + calculateTableSize(table).radius - 10 - 16
                : table.position.y + calculateTableSize(table).height + 10,
          }}
          fill="#000"
          fontSize={16}
          fontFamily="Inter Tight"
          fontStyle='bold'
          bold={true}
        />
      )}

      <Text
        text={String(table.capacity)}
        position={{
          x:
            table.position.x +
            (table.shape === TableShape.CIRCLE
              ? calculateTableSize(table).radius
              : calculateTableSize(table).width) -
            6 -
            32,
          y:
            table.position.y +
            (table.shape === TableShape.CIRCLE
              ? calculateTableSize(table).radius
              : calculateTableSize(table).height - 6 - 32),
        }}
        fill="#000"
        fontFamily="Inter Tight"
        fontSize={32}
      />
    </>
  );
};
