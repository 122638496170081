import React, { useEffect } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  customerSelector,
  setIsCreatingCustomer,
  setNewCustomerValue,
} from '../../features/customer.feature';
import { addCustomer } from '../../features/customer.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  height: '85vh',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  maxHeight: '90vh',
  overflowY: 'scroll',
  p: 4,
};

export const CreateCustomerModal = () => {
  const dispatch = useDispatch();
  const { loading, newCustomer, isCreatingCustomer } =
    useSelector(customerSelector);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isCreatingCustomer}
      onClose={() => dispatch(setIsCreatingCustomer(false))}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <>
        <Fade in={isCreatingCustomer}>
          <div className="container">
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Nuovo cliente
              </Typography>

              <div className="w-100 mt-2">
                <>
                  <TextField
                    className="mt-2"
                    label="Nome"
                    value={newCustomer?.firstName || ''}
                    onChange={(e) =>
                      dispatch(setNewCustomerValue('firstName', e.target.value))
                    }
                    variant={'filled'}
                    fullWidth
                  />

                  <TextField
                    className="mt-2"
                    label="Cognome"
                    value={newCustomer?.lastName || ''}
                    onChange={(e) =>
                      dispatch(setNewCustomerValue('lastName', e.target.value))
                    }
                    variant={'filled'}
                    fullWidth
                  />

                  <TextField
                    className="mt-2"
                    label="Email"
                    value={newCustomer?.email || ''}
                    onChange={(e) =>
                      dispatch(setNewCustomerValue('email', e.target.value))
                    }
                    variant={'filled'}
                    fullWidth
                  />

                  <TextField
                    className="mt-2"
                    label="Phone"
                    value={newCustomer?.phone || ''}
                    onChange={(e) =>
                      dispatch(setNewCustomerValue('phone', e.target.value))
                    }
                    variant={'filled'}
                    fullWidth
                  />

                  <TextField
                    className="mt-2"
                    label="Note"
                    value={newCustomer?.notes || ''}
                    onChange={(e) =>
                      dispatch(setNewCustomerValue('notes', e.target.value))
                    }
                    variant={'filled'}
                    rows={3}
                    multiline
                    fullWidth
                  />
                </>

                <Button
                  className="mt-2"
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(addCustomer())}
                  fullWidth
                >
                  Aggiungi cliente
                </Button>

                <Button
                  variant="outlined"
                  className="mt-1"
                  color="info"
                  fullWidth
                  onClick={() => dispatch(setIsCreatingCustomer(false))}
                >
                  Chiudi
                </Button>
              </div>
            </Box>
          </div>
        </Fade>
      </>
    </Modal>
  );
};
