/**
 * SimpleLayout
 * A layout should be rendered by a Page, and should
 * render some modules inside of it.
 * It's preferred to render children passed from parent (this.props.children)
 * @class SimpleLayout
 * @param {object} props - Props
 */

import React from 'react';
import { Topbar } from '../../modules/top-bar';

export const SimpleLayout = ({
  fullHeight,
  children,
  className,
}: {
  fullHeight?: boolean;
  children?: React.ReactElement | React.ReactElement[];
  className?: string;
}) => {
  return (
    <div className={fullHeight ? 'h-100' : ''}>
      <div className={'container fluid pt-20 ' + (className || '')}>
        <Topbar />
      </div>
      <div className={'container pt-6 mx-auto px-4 ' + (className || '')}>
        {children}
      </div>
    </div>
  );
};
