import React, { useEffect } from 'react';
import { Map } from '../../../types/map';
import { calculateTableSize } from './utils';
import { Table, TableShape } from '../../../types/table';
import { Reservation } from '../../../types/reservation';
import { Stage, Layer, Text, Rect, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { omit } from '../../utils/object.util';
import { Button } from '@mui/material';
import { TableSprite } from './table-shape';
import { Download } from '@mui/icons-material';

/**
 * Map designer component
 *
 * @param param0
 * @returns
 */
export const MapDesigner = ({
  map,
  onSelect,
  onUpdate,
  reservedTables,
  drawMode = true,
}: {
  map: Map;
  onUpdate: (map: Map) => void;
  onSelect: (table: Table, index: number) => void;
  reservedTables?: Record<string, Reservation>;
  drawMode?: boolean;
}) => {
  const stage = React.useRef(null);
  const [tables, setTables] = React.useState<
    (Table & { isDragging?: boolean })[]
  >(map.tables || []);

  const [stagePos, setStagePos] = React.useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [scale, setScale] = React.useState<{ x: number; y: number }>({
    x: 1,
    y: 1,
  });

  useEffect(() => {
    setTables(map.tables);
  }, [map]);

  /**
   * Table drag start event handler
   *
   * @param event
   */
  const onTableDragStart = (event: KonvaEventObject<DragEvent>, i: number) => {
    event.cancelBubble = true;

    setTables(
      tables.map((table, k) => ({
        ...table,
        isDragging: k === i,
      })),
    );
  };

  /**
   * Table drag end event handler
   *
   * @param event
   */
  const onTableDragEnd = (event: KonvaEventObject<DragEvent>, i: number) => {
    event.cancelBubble = true;

    setTables(
      tables.map((table) => {
        return {
          ...table,
          isDragging: false,
        };
      }),
    );

    onUpdate({
      ...map,
      tables: tables.map((t) => omit('isDragging', t)) as Table[],
    });
  };

  /**
   * Table drag move event handler
   *
   * @param event
   */
  const onTableDragMove = (event: KonvaEventObject<DragEvent>, i: number) => {
    event.cancelBubble = true;

    setTables(
      tables.map((table, k) => {
        return {
          ...table,
          position: {
            x: k === i ? event.target.x() : table.position.x,
            y: k === i ? event.target.y() : table.position.y,
          },
        };
      }),
    );
  };

  /**
   * Zoom stage event handler
   *
   * @param event
   */
  const zoomStage = (event: any) => {
    event.cancelBubble = true;

    const { deltaY } = event.evt;
    if ((scale.x > 3.5 && deltaY > 0) || (scale.x <= 0.15 && deltaY < 0))
      return;

    const rawX = scale.x + deltaY * 0.01;
    const rawY = scale.y + deltaY * 0.01;
    const scaleX = rawX < 0.15 ? 0.15 : rawX;
    const scaleY = rawY < 0.15 ? 0.15 : rawY;

    setScale({
      x: scaleX,
      y: scaleY,
    });
  };

  const onStageDragMove = (event: KonvaEventObject<DragEvent>) => {
    event.cancelBubble = true;
    setStagePos({
      x: event.target.x(),
      y: event.target.y(),
    });
  };

  const handleExport = () => {
    const uri = (stage.current as any).toDataURL();
    var link = document.createElement('a');
    link.download = 'export.png';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Stage
        ref={stage}
        width={window.innerWidth}
        height={window.innerHeight - 150}
        options={{
          backgroundColor: 0x131a2a,
        }}
        style={{
          overflow: 'hidden',
          height: '100%',
          flexGrow: 1,
          backgroundImage: 'url(/img/dots.svg)',
        }}
        onWheel={zoomStage}
        scale={scale}
        position={stagePos}
        onDragMove={onStageDragMove}
        draggable={true}
      >
        <Layer>
          {tables.map(
            (table, i) =>
              table.isActive && (
                <TableSprite
                  table={table}
                  reservedTables={reservedTables}
                  onTableDragStart={(e) => onTableDragStart(e, i)}
                  onTableDragMove={(e) => onTableDragMove(e, i)}
                  onTableDragEnd={(e) => onTableDragEnd(e, i)}
                  onSelect={() => onSelect(table, i)}
                  drawMode={drawMode}
                />
              ),
          )}
        </Layer>
      </Stage>

      <div className="fixed bottom-0 left-0 text-left p-1">
        <p>
          <Button onClick={handleExport} color="primary" variant="contained">
            <Download />
          </Button>
        </p>
        <p className="mt-1">
          <Button
            onClick={() =>
              scale.x < 3.5
                ? setScale({ x: scale.x + 0.15, y: scale.y + 0.15 })
                : null
            }
            color="primary"
            variant="contained"
          >
            +
          </Button>
          <p className="mt-1">
            Zoom: <b>{Math.round(scale.x * 100)}%</b>
          </p>
          <Button
            onClick={() =>
              scale.x > 0.15
                ? setScale({ x: scale.x - 0.15, y: scale.y - 0.15 })
                : null
            }
            className="mt-1"
            variant="contained"
            color="primary"
          >
            -
          </Button>
        </p>

        <Button
          color="info"
          className="mt-1"
          onClick={() => {
            setScale({ x: 1, y: 1 });
            setStagePos({ x: 0, y: 0 });
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
