import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppThunk, RootState } from '../..';
import { Customer } from '../../types/customers';
import {
  createCustomer,
  deleteCustomer,
  editCustomer,
  getCustomer,
  getRestaurant,
} from '../services/api';
import { omit } from '../utils/object.util';
import { loadCustomers } from './customers.feature';
import { addNotification, NotificationType } from './notifications.feature';

const initialState: {
  customer?: Customer;
  isCreatingCustomer: boolean;
  newCustomer?: Customer;
  loading: boolean;
  error: string | null;
} = {
  isCreatingCustomer: false,
  loading: false,
  error: null,
};

const customerFeature = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setNewCustomer: (state, action) => {
      state.newCustomer = action.payload;
    },
    setIsCreatingCustomer: (state, action) => {
      if (action.payload === false) {
        state.newCustomer = undefined;
      }
      state.isCreatingCustomer = action.payload;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const loadCustomer =
  (customerId: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await getCustomer(customerId);
      dispatch(setCustomer(data));
    } catch (e) {
      dispatch(setError((e as AxiosError).toString()));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setNewCustomerValue =
  (key: keyof Customer, value: any): AppThunk =>
  (dispatch, getState) => {
    const { newCustomer } = customerSelector(getState());
    dispatch(
      setNewCustomer({
        ...newCustomer,
        [key]: value,
      }),
    );
  };

export const addCustomer = (): AppThunk => async (dispatch, getState) => {
  const { newCustomer } = customerSelector(getState());
  dispatch(setLoading(true));
  try {
    const { data: restaurant } = await getRestaurant();
    const { data } = await createCustomer(
      omit(
        'id',
        {
          ...newCustomer,
          email:
            newCustomer?.email && newCustomer?.email !== ''
              ? newCustomer?.email
              : undefined,
        }!,
      ) as Omit<Customer, 'id'>,
      restaurant.id,
    );
    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: `Cliente ${data.id} creato con successo`,
      }),
    );
    dispatch(loadCustomers());
    dispatch(setIsCreatingCustomer(false));
  } catch (e) {
    const error = e as AxiosError;
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: (error.response?.data as any).message.toString(),
      }),
    );
  }
};

export const setCustomerValue =
  (key: keyof Customer, value: any): AppThunk =>
  (dispatch, getState) => {
    const { customer } = customerSelector(getState());
    dispatch(
      setCustomer({
        ...customer,
        [key]: value,
      }),
    );
  };

export const updateCustomer = (): AppThunk => async (dispatch, getState) => {
  const { customer } = customerSelector(getState());
  dispatch(setLoading(true));
  try {
    const { data } = await editCustomer(
      customer?.id!,
      omit('reservations', customer!),
    );
    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: 'Cliente aggiornato con successo',
      }),
    );
  } catch (e) {
    const error = e as AxiosError;
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: (error.response?.data as any).message.toString(),
      }),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeCustomer =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      await deleteCustomer(id);
      dispatch(
        addNotification({
          type: NotificationType.Success,
          message: `Cliente ${id} eliminato con successo`,
        }),
      );
    } catch (e) {
      const error = e as AxiosError;
      dispatch(
        addNotification({
          type: NotificationType.Error,
          message: (error.response?.data as any).message.toString(),
        }),
      );
    }
  };

export const customerSelector = (state: RootState) => state.customer;

export const {
  setLoading,
  setError,
  setCustomer,
  setNewCustomer,
  setIsCreatingCustomer,
} = customerFeature.actions;
export default customerFeature.reducer;
