export type Restaurant = {
  id: string;
  name: string;
  ownerId: string;
  defaultMapId: string;
};

export enum TimeslotType {
  RECURRENT = 'recurrent',
  SINGLE = 'single',
  CLOSED = 'closed',
}

export type TimeslotRules = {
  start: string; // HH:mm
  end: string; // HH:mm
  days?: number[];
  specificDate?: number;
};

export type MapException = {
  id?: string;
  mapId: string;
  timeslotId: string;
  date: string;
};

export type Timeslot = {
  id?: string;
  restaurantId: string;
  name: string;
  type: TimeslotType;
  rules: TimeslotRules;
  customMapId?: string;
  reservations?: any[];
  mapExceptions?: MapException[];
  isActive: boolean;
};
