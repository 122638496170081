import React, { useEffect } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewMap,
  mapsSelector,
  setIsCreatingNewMap,
  updateNewMapValue,
} from '../../features/maps.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  height: '85vh',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  maxHeight: '90vh',
  overflowY: 'scroll',
  p: 4,
};

export const CreateMapModal = () => {
  const dispatch = useDispatch();
  const { loading, newMap, isCreatingNewMap } = useSelector(mapsSelector);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isCreatingNewMap}
      onClose={() => dispatch(setIsCreatingNewMap(false))}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <>
        <Fade in={isCreatingNewMap}>
          <div className="container">
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Nuova mappa
              </Typography>

              <div className="w-100 mt-2">
                <>
                  <TextField
                    className="mt-2"
                    label="Nome"
                    value={newMap?.name || ''}
                    onChange={(e) =>
                      dispatch(updateNewMapValue('name', e.target.value))
                    }
                    variant={'filled'}
                    fullWidth
                  />
                </>

                <Button
                  className="mt-2"
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(createNewMap())}
                  fullWidth
                >
                  Aggiungi mappa
                </Button>

                <Button
                  variant="outlined"
                  className="mt-1"
                  color="info"
                  fullWidth
                  onClick={() => dispatch(setIsCreatingNewMap(false))}
                >
                  Chiudi
                </Button>
              </div>
            </Box>
          </div>
        </Fade>
      </>
    </Modal>
  );
};
