import { createTheme, TypeText } from '@mui/material';
import { amber, green, lime, pink, purple, yellow } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: amber,
    secondary: {
      main: '#004d40',
    },
  },
  typography: {
    fontFamily: ['"Inter Tight"'].join(','),
  },
});

export default theme;
