import React, { useEffect } from 'react';
import { Backdrop, Button, Fade, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Timeslot } from '../../../types/restaurant';
import {
  reservationSelector,
  selectTableAndProceed,
  setIsModalOpen,
  setReservation,
} from '../../features/reservation.feature';
import { MapDesigner } from '../map-designer';
import { mapSelector } from '../../features/map.feature';
import { Loader } from '../../components/loader';
import { Reserve } from './reserve';
import { Table } from '../../../types/table';
import moment from 'moment';
import { Reservation as ReservationType } from '../../../types/reservation';
import {
  createCloneForTimeslotAndDate,
  restaurantSelector,
} from '../../features/restaurant.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: '100%',
  height: '85vh',
  maxWidth: 1100,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  p: 0,
};

export const Reservation = () => {
  const dispatch = useDispatch();
  const { map, loading } = useSelector(mapSelector);
  const { loadingMapClone } = useSelector(restaurantSelector);
  const { isModalOpen, timeslot, reservation } =
    useSelector(reservationSelector);
  const [reservedTables, setReservedTables] = React.useState<
    Record<string, ReservationType>
  >({});

  useEffect(() => {
    if (timeslot) {
      refreshReservedTables();
    }
  }, [timeslot, map]);

  const refreshReservedTables = () => {
    const day = moment(reservation.date).startOf('day').valueOf();
    const reservationsOfTheDay =
      timeslot?.reservations?.filter((r) => {
        return parseInt(r.date) === day && r.isActive;
      }) || [];

    const reservedTables = reservationsOfTheDay.reduce((obj, value) => {
      obj[value.tableId] = value;
      return obj;
    }, {});
    setReservedTables(reservedTables);
  };

  const editMap = () => {
    if (map && timeslot?.id && reservation.date) {
      if (map?.isHidden) {
        window.open(`/maps/${map?.id}`, '_blank');
      } else {
        dispatch(
          createCloneForTimeslotAndDate(
            timeslot.id,
            map.id,
            moment(reservation.date).startOf('day').valueOf(),
          ),
        );
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={() => dispatch(setIsModalOpen(false))}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <div className="container">
            <Box sx={style}>
              <div className="pt-1 px-1 flex justify-between items-center">
                <h2 className="text-2xl font-bold">{map?.name || 'Sala'}</h2>

                <Button onClick={editMap} disabled={loadingMapClone}>
                  Modifica
                </Button>
              </div>

              <div className="w-100 mt-2">
                {!loading && map ? (
                  <MapDesigner
                    map={map}
                    reservedTables={reservedTables}
                    onSelect={(table, index) =>
                      dispatch(
                        selectTableAndProceed(
                          table,
                          reservedTables[table.id]
                            ? reservedTables[table.id]
                            : undefined,
                        ),
                      )
                    }
                    onUpdate={() => {}}
                    drawMode={false}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Reserve />
    </>
  );
};
