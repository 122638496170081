import { Table, TableOrientation, TableShape } from '../../types/table';

export const TABLE_SEAT_SIZE = 80;

export const craftTable = ({
  mapId,
  capacity = 4,
  shape = TableShape.RECTANGLE,
  orientation = TableOrientation.HORIZONTAL,
  humanReadableCode = '#',
  position = { x: 0, y: 0 },
}: {
  mapId: string;
  capacity?: number;
  shape?: TableShape;
  orientation?: TableOrientation;
  humanReadableCode?: string;
  position?: { x: number; y: number };
}): Table => ({
  id: '',
  mapId,
  capacity,
  shape,
  orientation,
  humanReadableCode,
  position,
  isActive: true,
});
