import { Add } from '@mui/icons-material';
import { Fab, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Customer } from '../../../types/customers';
import { Loader } from '../../components/loader';
import { setIsCreatingCustomer } from '../../features/customer.feature';
import {
  customersSelector,
  loadCustomers,
  searchCustomersWithFilters,
  setIsFiltering,
} from '../../features/customers.feature';
import { SimpleLayout } from '../../layouts/simple-layout';
import { CreateCustomerModal } from '../../modules/create-customer-modal';
import DataTable from '../../modules/datatable';
import {
  FilterType,
  SearchFiltersModal,
} from '../../modules/search-filters-modal';
import moment from 'moment';

export const Customers = () => {
  const dispatch = useDispatch();
  const { customers, loading, isFiltering, searchQuery } =
    useSelector(customersSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadCustomers());
  }, [dispatch]);

  const handleExport = () => {
    const cols = [
      'Nome',
      'Cognome',
      'Email',
      'Telefono',
      'Note cliente',
      'Data creazione',
    ];
    const csvContent =
      cols.join(',') +
      '\n' +
      customers
        .map((e) =>
          [
            String(e.firstName || '').replaceAll(
              new RegExp(/([;,"'])/, 'g'),
              ' ',
            ),
            String(e.lastName || '').replaceAll(
              new RegExp(/([;,"'])/, 'g'),
              ' ',
            ),
            String(e.email || '').replaceAll(new RegExp(/([;,"'])/, 'g'), ' '),
            String(e.phone || '').replaceAll(new RegExp(/([;,"'])/, 'g'), ' '),
            String(e.notes || '').replaceAll(new RegExp(/([;,"'])/, 'g'), ' '),
            moment(e.createdAt).format('DD/MM/YYYY HH:mm'),
          ].join(','),
        )
        .join('\n');

    const blobURL = URL.createObjectURL(
      new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }),
    );

    const blobLink = document.createElement('a');
    blobLink.href = blobURL;
    blobLink.download = `EXPORT_${moment().format('YYYYMMDDTHHmmss')}`;
    blobLink.click();

    URL.revokeObjectURL(blobURL);
  };

  return (
    <SimpleLayout>
      <h1 className="font-bold">
        {loading ? (
          <Skeleton variant="text" width={200} animation="wave" />
        ) : (
          'Clienti'
        )}
      </h1>

      <div className="mt-2">
        {loading ? (
          <Skeleton variant="rounded" animation="wave" height={500} />
        ) : (
          <DataTable
            onRowClick={(row: Customer) => navigate('/customers/' + row.id)}
            rows={customers}
            onFilterClick={() => dispatch(setIsFiltering(true))}
            onExportClick={handleExport}
            columns={[
              {
                id: 'firstName',
                name: 'Nome',
                selector: (row: Customer) => row.firstName,
              },
              {
                id: 'lastName',
                name: 'Cognome',
                selector: (row: Customer) => row.lastName,
              },
              {
                id: 'phone',
                name: 'Telefono',
                selector: (row: Customer) => row.phone,
              },
              {
                id: 'email',
                name: 'Email',
                selector: (row: Customer) => row.email,
              },
            ]}
          />
        )}

        <SearchFiltersModal
          open={isFiltering}
          activeQuery={searchQuery}
          onFiltersChanged={(query) =>
            dispatch(searchCustomersWithFilters(query))
          }
          filters={[
            {
              type: FilterType.TEXT,
              name: 'firstName',
              label: 'Nome',
            },
            {
              type: FilterType.TEXT,
              name: 'lastName',
              label: 'Cognome',
            },
            {
              type: FilterType.TEXT,
              name: 'email',
              label: 'Email',
            },
            {
              type: FilterType.TEXT,
              name: 'phone',
              label: 'Telefono',
            },
          ]}
        />

        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => {
            dispatch(setIsCreatingCustomer(true));
          }}
          style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
        >
          <Add />
        </Fab>
      </div>

      <CreateCustomerModal />
    </SimpleLayout>
  );
};
