import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { AppThunk, RootState } from '../..';
import { Customer } from '../../types/customers';
import { Reservation } from '../../types/reservation';
import { Timeslot } from '../../types/restaurant';
import { Table } from '../../types/table';
import {
  createCustomer,
  createReservation,
  deleteReservation,
  getReservation,
  getRestaurant,
  getTimeslot,
  searchReservations,
  updateReservation,
} from '../services/api';
import { omit } from '../utils/object.util';
import { loadMap } from './map.feature';
import { addNotification, NotificationType } from './notifications.feature';
import { loadTimeslots } from './restaurant.feature';
import { loadReservations } from './reservations.feature';

export type ReservationExtended = {
  id?: string;
  table?: Table;
  start?: string;
  end?: string;
  date?: number;
  guests: number;
  customer?: Customer;
  timeslot?: Timeslot;
  referral?: string;
  notes?: string;
};

export type ReservationFeature = {
  reservation: ReservationExtended;
  timeslot?: Timeslot;
  isModalOpen: boolean;
};

const initialState: ReservationFeature = {
  isModalOpen: false,
  reservation: {
    guests: 1,
  },
};

export const reservationFeature = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    resetReservation: (state) => {
      state.reservation = {
        ...initialState.reservation,
        date: state.reservation.date,
      };
    },
    initReservation: (state, action: PayloadAction<ReservationFeature>) => {
      state.reservation = action.payload.reservation;
      state.timeslot = action.payload.timeslot;
    },
    setReservation: (state, action) => {
      state.reservation = action.payload;
    },
    setTimeslot: (state, action) => {
      state.timeslot = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const openWithExistingReservation =
  (reservation: Reservation): AppThunk =>
  async (dispatch) => {
    dispatch(setReservation(reservation));
    dispatch(setTimeslot(reservation.timeslot));
    dispatch(setReservationDate(parseInt(reservation.date)));
    dispatch(loadMap(reservation.table.mapId));
    // dispatch(setIsModalOpen(true));
  };

export const startReservation =
  ({ timeslotId, event }: { timeslotId?: string; event: any }): AppThunk =>
  async (dispatch, getState) => {
    if (timeslotId) {
      const { reservation } = reservationSelector(getState());
      const { data }: { data: Timeslot } = await getTimeslot(timeslotId);
      const { data: slotReservations } = await searchReservations({
        timeslotId,
        fromDate: moment(event.start).startOf('day').valueOf(),
        toDate: moment(event.start).endOf('day').valueOf(),
      })
      const { data: restaurant } = await getRestaurant();

      dispatch(setTimeslot({ ...data, reservations: slotReservations }));
      dispatch(
        setReservation({ ...reservation, date: moment(event.start).valueOf() }),
      );

      const mapException = data.mapExceptions?.find(
        (ex) =>
          parseInt(ex.date) === moment(event.start).startOf('day').valueOf() &&
          ex.timeslotId === timeslotId,
      );

      const mapId = mapException
        ? mapException.mapId
        : data.customMapId || restaurant.defaultMapId;
      dispatch(loadMap(mapId));
    }
    dispatch(setIsModalOpen(true));
  };

export const selectTableAndProceed =
  (table: Table, reservation?: Reservation): AppThunk =>
  async (dispatch, getState) => {
    if (reservation) {
      try {
        const { data } = await getReservation(reservation.id!);
        dispatch(setReservation({ ...data, date: parseInt(data.date) }));
      } catch (e) {
        const error = e as AxiosError;
        dispatch(
          addNotification({
            type: NotificationType.Error,
            message: (error.response?.data as any).message,
          }),
        );
      }
    } else {
      const { reservation } = reservationSelector(getState());
      dispatch(setReservation({ ...reservation, table }));
    }
  };

export const deselectTableAndGoBack =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(resetReservation());
    // dispatch(setIsModalOpen(false));
  };

export const setReservationDate =
  (date: number): AppThunk =>
  async (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());
    dispatch(setReservation({ ...reservation, date }));
  };

export const setReservationTable =
  (table: Table): AppThunk =>
  async (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());
    dispatch(setReservation({ ...reservation, table, tableId: table.id }));
  };

export const setReservationTimeslot =
  (timeslot: Timeslot): AppThunk =>
  async (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());
    const { data }: { data: Timeslot } = await getTimeslot(timeslot.id!);
    const { data: restaurant } = await getRestaurant();
    dispatch(
      setReservation({ ...reservation, timeslotId: timeslot.id, timeslot }),
    );
    const mapException = data.mapExceptions?.find(
      (ex) =>
        parseInt(ex.date) ===
          moment(reservation.date).startOf('day').valueOf() &&
        ex.timeslotId === timeslot.id,
    );

    const mapId = mapException
      ? mapException.mapId
      : data.customMapId || restaurant.defaultMapId;
    dispatch(loadMap(mapId));
    dispatch(setTimeslot({ timeslot }));
  };

export const updateReservationCustomer =
  (customer?: Partial<Customer>): AppThunk =>
  (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());
    if (!customer && reservation?.customer) {
      dispatch(setReservation({ ...omit('customer', reservation) }));
    } else {
      dispatch(
        setReservation({
          ...reservation,
          customer: { ...reservation?.customer, ...customer },
        }),
      );
    }
  };

export const setReservationCustomerValue =
  (key: keyof Customer, value: any): AppThunk =>
  async (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());
    dispatch(
      setReservation({
        ...reservation,
        customer: { ...reservation?.customer, [key]: value },
      }),
    );
  };

export const setReservationNotes =
  (notes: string): AppThunk =>
  (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());

    dispatch(
      setReservation({
        ...reservation,
        notes,
      }),
    );
  };

export const setReservationReferral =
  (referral: string): AppThunk =>
  (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());

    dispatch(
      setReservation({
        ...reservation,
        referral,
      }),
    );
  };

export const setReservationGuests =
  (guests: number): AppThunk =>
  (dispatch, getState) => {
    const { reservation } = reservationSelector(getState());

    dispatch(
      setReservation({
        ...reservation,
        guests,
      }),
    );
  };

export const saveReservation = (): AppThunk => async (dispatch, getState) => {
  const { reservation, timeslot } = reservationSelector(getState());
  let customerId = reservation.customer?.id;

  if (!timeslot || !reservation.table || !reservation.customer) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message:
          'Per creare una prenotazione sono necessari un tavolo e i dati del cliente',
      }),
    );
    return;
  }

  const { data: restaurant } = await getRestaurant();

  if (!reservation.customer?.id) {
    try {
      // if customer is new, create it
      // if (!reservation.customer.phone) {
      //   dispatch(
      //     addNotification({
      //       type: NotificationType.Error,
      //       message:
      //         'Per aggiungere un nuovo cliente è necessario almeno il suo numero di telefono',
      //     }),
      //   );
      //   return;
      // }

      const { data } = await createCustomer(
        reservation.customer,
        restaurant.id,
      );

      customerId = data.id;
    } catch (e) {
      dispatch(
        addNotification({
          type: NotificationType.Error,
          message: `Errore nella creazione del cliente: ${
            ((e as AxiosError).response?.data as any).message
          }`,
        }),
      );
      return;
    }
  }

  try {
    await createReservation(
      reservation,
      timeslot,
      restaurant.id,
      customerId as string,
    );
    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: 'Prenotazione creata con successo',
      }),
    );
    dispatch(setIsModalOpen(false));
    dispatch(setReservation({ guests: 1 }));
    dispatch(loadTimeslots());
  } catch (e) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: `Errore nella creazione della prenotazione: ${
          ((e as AxiosError).response?.data as any).message
        }`,
      }),
    );
  }
};

export const editReservation = (): AppThunk => async (dispatch, getState) => {
  const { reservation } = reservationSelector(getState());
  try {
    await updateReservation(
      reservation.id!,
      omit(['table', 'customer', 'timeslot'], reservation),
    );
    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: 'Prenotazione modificata con successo',
      }),
    );
  } catch (e) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: `Errore nella modifica della prenotazione: ${
          ((e as AxiosError).response?.data as any).message
        }`,
      }),
    );
  }
};

export const removeReservation = (): AppThunk => async (dispatch, getState) => {
  const { reservation } = reservationSelector(getState());

  try {
    await deleteReservation(reservation.id!);

    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: 'Prenotazione eliminata con successo',
      }),
    );

    dispatch(loadTimeslots());
    dispatch(resetReservation());
    dispatch(loadReservations());
    dispatch(setIsModalOpen(false));
  } catch (e) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: `Errore nella rimozione della prenotazione: ${
          ((e as AxiosError).response?.data as any).message
        }`,
      }),
    );
  }
};

export const reservationSelector = (state: RootState) => state.reservation;

export const {
  setReservation,
  setTimeslot,
  setIsModalOpen,
  initReservation,
  resetReservation,
} = reservationFeature.actions;
export default reservationFeature.reducer;
