import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AppThunk, RootState } from '../..';
import {
  MapException,
  Restaurant,
  Timeslot,
  TimeslotType,
} from '../../types/restaurant';
import {
  createMapException,
  createTimeslot,
  deleteTimeslot,
  getRestaurant,
  getTimeslots,
  updateRestaurant,
  updateTimeslot,
} from '../services/api';
import { omit } from '../utils/object.util';
import { addNotification, NotificationType } from './notifications.feature';
import { loadMap } from './map.feature';
import { loadReservations } from './reservations.feature';

type RestaurantFeature = {
  restaurant?: Restaurant;
  timeslots: Timeslot[];
  loading: boolean;
  loadingTimeslots: boolean;
  deletedTimeslotsIds: string[];
  loadingMapClone: boolean;
  error: string | null;
};

const initialState: RestaurantFeature = {
  loading: false,
  loadingTimeslots: false,
  loadingMapClone: false,
  deletedTimeslotsIds: [],
  timeslots: [],
  error: null,
};

const restaurantFeature = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurant: (state, action) => {
      state.restaurant = action.payload;
    },
    setTimeslot: (
      state,
      action: PayloadAction<Timeslot & { index: number }>,
    ) => {
      state.timeslots[action.payload.index] = omit(
        'index',
        action.payload,
      ) as Timeslot;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingTimeslots: (state, action) => {
      state.loadingTimeslots = action.payload;
    },
    setLoadingMapClone: (state, action) => {
      state.loadingMapClone = action.payload;
    },
    setDeletedTimeslotsIds: (state, action) => {
      state.deletedTimeslotsIds = action.payload;
    },
    addDeletedTimeslotsId: (state, action) => {
      state.deletedTimeslotsIds.push(action.payload);
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTimeslots: (state, action) => {
      state.timeslots = action.payload;
    },
  },
});

export const restaurantSelector = (state: RootState) => state.restaurant;

export const loadRestaurant = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    dispatch(loadTimeslots());
    const { data } = await getRestaurant();
    dispatch(setRestaurant(data));
  } catch (e) {
    dispatch(setError((e as AxiosError).toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const loadTimeslots = (): AppThunk => async (dispatch) => {
  try {
    const { data } = await getTimeslots();
    dispatch(setTimeslots(data));
  } catch (e) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: `Errore nel caricamento dei timeslot: ${
          ((e as AxiosError).response?.data as any).message
        }`,
      }),
    );
  }
};

export const addTimeslot = (): AppThunk => async (dispatch, getState) => {
  const { timeslots, restaurant } = restaurantSelector(getState());
  const newTimeslot: Timeslot = {
    type: TimeslotType.RECURRENT,
    name: '',
    restaurantId: restaurant?.id!,
    rules: {
      start: '18:00',
      end: '20:00',
    },
    isActive: true,
  };
  dispatch(setTimeslots([...timeslots, newTimeslot]));
};

export const removeTimeslot =
  (index: number): AppThunk =>
  async (dispatch, getState) => {
    const { timeslots } = restaurantSelector(getState());
    dispatch(addDeletedTimeslotsId(timeslots[index].id));
    const newTimeslots = timeslots.filter((t, i) => i !== index);
    dispatch(setTimeslots(newTimeslots));
  };

export const saveRestaurant = (): AppThunk => async (dispatch, getState) => {
  const { restaurant } = restaurantSelector(getState());

  dispatch(setLoading(true));

  try {
    await updateRestaurant(restaurant!);
    dispatch(
      addNotification({
        message: 'Ristorante salvato con successo',
        type: NotificationType.Success,
      }),
    );
  } catch (e) {
    dispatch(setError((e as AxiosError).toString()));
    dispatch(
      addNotification({
        message: 'Errore durante il salvataggio del ristorante',
        type: NotificationType.Error,
      }),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveTimeslots = (): AppThunk => async (dispatch, getState) => {
  const { timeslots, deletedTimeslotsIds } = restaurantSelector(getState());

  dispatch(setLoadingTimeslots(true));

  try {
    await Promise.all(
      timeslots
        .filter((t) => t.id)
        .map((t) => updateTimeslot({ ...t, id: t.id! })),
    );
    await Promise.all([deletedTimeslotsIds.map((id) => deleteTimeslot(id))]);
    await Promise.all(
      timeslots.filter((t) => !t.id).map((t) => createTimeslot(t)),
    );

    dispatch(
      addNotification({
        message: 'Timeslots salvati con successo',
        type: NotificationType.Success,
      }),
    );

    dispatch(setDeletedTimeslotsIds([]));
  } catch (e) {
    dispatch(setError((e as AxiosError).toString()));
    dispatch(
      addNotification({
        message: 'Errore nel salvataggio dei timeslots',
        type: NotificationType.Error,
      }),
    );
  } finally {
    dispatch(setLoadingTimeslots(false));
    setTimeout(() => dispatch(loadTimeslots()), 100);
  }
};

export const createCloneForTimeslotAndDate =
  (timeslotId: string, mapId: string, date: number): AppThunk =>
  async (dispatch, getState) => {
    const { loadingMapClone } = restaurantSelector(getState());
    if (loadingMapClone) return;
    dispatch(setLoadingMapClone(true));

    const { data: clone }: { data: MapException } = await createMapException(
      timeslotId,
      mapId,
      date,
    );

    dispatch(loadReservations());
    dispatch(loadMap(clone.mapId));
    dispatch(setLoadingMapClone(false));
    window.open(`/maps/${clone.mapId}`, '_blank');
  };

export const {
  setLoading,
  setRestaurant,
  setError,
  setTimeslots,
  setLoadingTimeslots,
  setLoadingMapClone,
  setTimeslot,
  setDeletedTimeslotsIds,
  addDeletedTimeslotsId,
} = restaurantFeature.actions;
export default restaurantFeature.reducer;
