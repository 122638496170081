import moment from 'moment';
import { Timeslot, TimeslotType } from '../../../types/restaurant';

export type CalendarEvent = {
  title: string;
  start?: string;
  startTime?: string;
  end?: string;
  endTime?: string;
  daysOfWeek?: number[];
  timeslotId: string;
};

export const buildEventsFromTimeslots = (
  timeslots: Timeslot[],
): CalendarEvent[] => {
  const events: CalendarEvent[] = [];
  for (const timeslot of timeslots) {
    switch (timeslot.type) {
      case TimeslotType.RECURRENT:
        events.push({
          title: timeslot.name,
          daysOfWeek: timeslot.rules.days,
          startTime: timeslot.rules.start,
          endTime: timeslot.rules.end,
          timeslotId: timeslot.id!,
        });
        break;
      case TimeslotType.SINGLE:
        events.push({
          title: timeslot.name,
          start: `${moment(timeslot.rules.specificDate).format('YYYY-MM-DD')} ${
            timeslot.rules.start
          }`,
          end: `${moment(timeslot.rules.specificDate).format('YYYY-MM-DD')} ${
            timeslot.rules.end
          }`,
          timeslotId: timeslot.id!,
        });
        break;
      case TimeslotType.CLOSED:
        events.push({
          title: timeslot.name,
          start: `${moment(timeslot.rules.specificDate).format('YYYY-MM-DD')}`,
          timeslotId: timeslot.id!,
        });
        break;
    }
  }

  return events;
};
