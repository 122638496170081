import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../..';

export enum NotificationType {
  Success = 'success',
  Error = 'error',
}

export type Notification = {
  id: string;
  message: string;
  type: NotificationType;
};

const initialState: {
  notifications: Notification[];
} = {
  notifications: [],
};

const notificationFeature = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<Omit<Notification, 'id'>>,
    ) => {
      state.notifications.push({
        id: uuidv4(),
        ...action.payload,
      });
    },
    consumeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload,
      );
    },
  },
});

export const notificationsSelector = (state: RootState) =>
  state.notifications.notifications;

export const { addNotification, consumeNotification } =
  notificationFeature.actions;
export default notificationFeature.reducer;
