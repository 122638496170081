import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
  consumeNotification,
  notificationsSelector,
} from '../../features/notifications.feature';
import 'react-toastify/dist/ReactToastify.css';

export const NotificationSystem = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);

  useEffect(() => {
    if (notifications.length > 0) {
      const notification = notifications[0];
      if (notification) {
        toast(notification.message, {
          type: notification.type,
          position: 'bottom-center',
          theme: 'dark',
        });
        dispatch(consumeNotification(notification.id));
      }
    }
  }, [notifications, dispatch]);

  return <ToastContainer />;
};
