import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../..';
import { Map } from '../../types/map';
import { Table } from '../../types/table';
import { createTable, getMap, updateMap, updateTables } from '../services/api';
import { omit } from '../utils/object.util';
import { addNotification, NotificationType } from './notifications.feature';

const initialState: {
  map?: Map;
  loading: boolean;
  error: string | null;
  selectedTable?: Table;
  selectedTableIndex?: number;
  selectedTableIsNew: boolean;
  updated: boolean;
} = {
  loading: true,
  error: null,
  selectedTableIsNew: false,
  updated: false,
};

const mapFeature = createSlice({
  name: 'map',
  initialState,
  reducers: {
    resetState: (state) => {
      for (const key in Object.keys(initialState)) {
        // @ts-ignore
        state[key] = initialState[key];
      }
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    addTable: (state, action) => {
      state.map?.tables.push(action.payload);
      state.selectedTableIsNew = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedTable: (state, action) => {
      state.selectedTable = action.payload;
    },
    setSelectedTableIndex: (state, action) => {
      state.selectedTableIndex = action.payload;
    },
    setSelectedTableIsNew: (state, action) => {
      state.selectedTableIsNew = action.payload;
    },
    setIsUpdated: (state, action) => {
      state.updated = action.payload;
    },
  },
});

export const loadMap =
  (id: string): AppThunk =>
  async (dispatch: Dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { data } = await getMap(id);
      dispatch(setMap(data));
    } catch (error) {
      console.log(error);
      dispatch(setError((error as Error).toString()));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateTable =
  (table: Table): AppThunk =>
  (dispatch: Dispatch, getState) => {
    const { map, selectedTable, selectedTableIndex } = getState().map;
    const tableToUpdate = map?.tables[selectedTableIndex!];

    if (!tableToUpdate || !map) {
      return;
    }

    // sync the selected table with the updated one
    dispatch(setSelectedTable(table));

    dispatch(
      setMap({
        ...map,
        tables: map.tables.map((t, i) =>
          i === selectedTableIndex ? table : t,
        ),
      }),
    );
  };

export const removeTable = (): AppThunk => async (dispatch, getState) => {
  const { selectedTable, map, selectedTableIndex } = getState().map;
  if (!selectedTable) return;

  dispatch(setSelectedTable(undefined));
  dispatch(
    setMap({
      ...map!,
      tables: map!.tables.map((t, i) =>
        selectedTableIndex === i
          ? {
              ...t,
              isActive: false,
            }
          : t,
      ),
    }),
  );
};

export const saveMapAndTables = (): AppThunk => async (dispatch, getState) => {
  const { map } = getState().map;
  if (!map) return;

  try {
    const tablesToCreate = map.tables
      .filter((t) => t.id === '' && t.isActive)
      .map((t) => omit('id', t));

    await Promise.all([
      ...tablesToCreate.map((t) => createTable(t)),
      updateTables(
        map.tables
          .filter((t) => t.id !== '')
          .map((t) => omit('reservations', t)),
      ),
      updateMap(omit('tables', map)),
    ]);

    dispatch(loadMap(map.id));

    dispatch(
      addNotification({
        type: NotificationType.Success,
        message: 'Mappa aggiornata',
      }),
    );
    dispatch(setIsUpdated(false));
  } catch (error) {
    dispatch(
      addNotification({
        type: NotificationType.Error,
        message: "Errore durante l'aggiornamento della mappa",
      }),
    );
  } finally {
  }
};

export const mapSelector = (state: RootState) => state.map;

export const {
  setMap,
  setLoading,
  setError,
  setSelectedTable,
  addTable,
  setSelectedTableIsNew,
  setSelectedTableIndex,
  setIsUpdated,
} = mapFeature.actions;
export default mapFeature.reducer;
