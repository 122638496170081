import { Button } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedSelector, keycloakLogin } from '../../features/user.feature';
import unlock from '../../../assets/lotties/unlock.json';
import login from '../../../assets/lotties/login.json';
import { Key } from '@mui/icons-material';

export const Login = ({ authenticating }: { authenticating: boolean }) => {
  const logged = useSelector(isLoggedSelector);

  return (
    <div className="relative fluid w-screen h-screen flex items-center justify-center bg-[#ffc007] overflow-hidden">
      {authenticating ? (
        <Lottie
          animationData={unlock}
          loop={!logged}
          initialSegment={[0, !logged ? 15 : 50]}
          className="h-[100px]"
        />
      ) : (
        <>
          <Button
            className="z-[1000]"
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => keycloakLogin()}
            disabled={authenticating}
          >
            <Key className={'mr-2'} />
            Accedi
          </Button>
          <Lottie
            animationData={login}
            loop={true}
            className="fixed bottom-[10px] right-[-100px] md:bottom-[-80px] lg:bottom-[-120px] md:right-[-300px] scale-[1.2] md:scale-100 opacity-[0.8]"
          />
        </>
      )}
    </div>
  );
};
