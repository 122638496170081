import {
  Delete,
  Edit,
  Map as MapIcon,
  Menu as MenuIcon,
  Settings,
  Star,
} from '@mui/icons-material';
import {
  Badge,
  Card,
  Chip,
  IconButton,
  ListItemIcon,
  MenuItem,
  Menu,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Map } from '../../../types/map';
import { askForConfirmation } from '../../features/confirmations.feature';
import { removeMap, setIsRenamingMap } from '../../features/maps.feature';

export const MapCollectionItem = ({
  map,
  isDefault = false,
}: {
  map: Map;
  isDefault?: boolean;
}) => {
  const totalCapacity = map.tables
    .filter((t) => t.isActive === true)
    .reduce((acc, t) => (acc += t.capacity), 0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    dispatch(
      askForConfirmation({
        message: 'Sei sicuro di voler eliminare questa mappa?',
        onConfirm: () => {
          dispatch(removeMap(id));
        },
      }),
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className="p-1" style={{ borderRadius: '16px' }}>
      <div className={'flex justify-between items-center'}>
        <h3 className={'text-2xl font-semibold'}>
          {isDefault && <Star color={'primary'} />} {map.name}
        </h3>

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate('/maps/' + map.id)}>
            <ListItemIcon>
              <MapIcon fontSize="small" />
            </ListItemIcon>
            Apri designer
          </MenuItem>
          <MenuItem onClick={() => dispatch(setIsRenamingMap(map))}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            Rinomina
          </MenuItem>
          <MenuItem onClick={() => handleDelete(map.id)}>
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            Elimina
          </MenuItem>
        </Menu>
      </div>

      <div className="mt-2 justify-between items-center flex">
        <p>Tavoli:</p>
        <Chip
          color="info"
          label={map.tables.filter((t) => t.isActive === true).length}
        />
      </div>

      <div className="mt-1 justify-between items-center flex">
        <p>Coperti:</p>
        <Chip color="info" label={totalCapacity} />
      </div>
    </Card>
  );
};
