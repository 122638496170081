import React, { useEffect } from 'react';
import {
  Autocomplete,
  Backdrop,
  Button,
  Chip,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  MobileDateTimePicker,
  LocalizationProvider,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  deselectTableAndGoBack,
  editReservation,
  removeReservation,
  reservationSelector,
  saveReservation,
  setReservationCustomerValue,
  setReservationDate,
  setReservationGuests,
  setReservationNotes,
  setReservationReferral,
  setReservationTable,
  setReservationTimeslot,
  updateReservationCustomer,
} from '../../features/reservation.feature';
import moment from 'moment';
import {
  customersSelector,
  loadCustomers,
} from '../../features/customers.feature';
import { Loader } from '../../components/loader';
import { Customer } from '../../../types/customers';
import { askForConfirmation } from '../../features/confirmations.feature';
import { mapSelector } from '../../features/map.feature';
import {
  loadTimeslots,
  restaurantSelector,
} from '../../features/restaurant.feature';
import 'moment/locale/it';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  height: '80vh',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  overflowY: 'scroll',
  p: 4,
};

export const Reserve = () => {
  const dispatch = useDispatch();
  const { reservation, timeslot } = useSelector(reservationSelector);
  const { customers, loading: isLoadingCustomers } =
    useSelector(customersSelector);
  const { map } = useSelector(mapSelector);
  const { timeslots } = useSelector(restaurantSelector);

  const [autocompleteInputValue, setAutocompleteInputValue] =
    React.useState('');

  useEffect(() => {
    dispatch(loadCustomers());
    dispatch(loadTimeslots());
    if (reservation.customer?.id) {
      setAutocompleteInputValue(
        `${reservation.customer.firstName} ${reservation.customer.lastName} ${reservation.customer.phone}`,
      );
    }
  }, [map, timeslot, reservation.customer, dispatch]);

  const selectExistingCustomer = (customer?: Customer) => {
    dispatch(updateReservationCustomer(customer));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={reservation?.table ? true : false}
      onClose={() => dispatch(deselectTableAndGoBack())}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={reservation?.table ? true : false}>
        <div className="container">
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {reservation.id ? 'Modifica' : 'Nuova'} prenotazione tavolo{' '}
              {reservation.table?.humanReadableCode}
            </Typography>

            <div className="w-100 mt-2">
              {reservation.id ? (
                <Tooltip title="Per modificare la data di una prenotazione è necessario eliminare la prenotazione attuale e crearne una nuova dal calendario.">
                  <FormControl className="mt-1" variant="filled" fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="it"
                    >
                      <MobileDatePicker
                        label={'Data'}
                        value={moment(reservation?.date)}
                        disabled
                        onChange={(e) =>
                          e
                            ? dispatch(
                                setReservationDate(e.startOf('day').valueOf()),
                              )
                            : null
                        }
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Tooltip>
              ) : (
                <FormControl className="mt-1" variant="filled" fullWidth>
                  <LocalizationProvider
                    locale={'it'}
                    dateAdapter={AdapterMoment}
                    adapterLocale="it"
                  >
                    <MobileDateTimePicker
                      label={'Data e ora'}
                      value={moment(reservation?.date)}
                      disabled
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

              {reservation.id && (
                <FormControl className="mt-2" variant={'filled'} fullWidth>
                  <InputLabel id="timeslot-select">Slot orario</InputLabel>
                  <Select
                    value={reservation?.timeslot?.id}
                    labelId="timeslot-select"
                    label="Slot orario"
                    variant={'filled'}
                    onChange={(e) =>
                      dispatch(
                        setReservationTimeslot(
                          timeslots.find((t) => t.id === e.target.value)!,
                        ),
                      )
                    }
                  >
                    {timeslots
                      ?.filter((t) => t.isActive)
                      .map((timeslot, i) => (
                        <MenuItem key={i} value={timeslot.id}>
                          <Chip
                            label={`${timeslot.rules.start} - ${timeslot.rules.end}`}
                            className={'mr-1'}
                          />
                          {timeslot.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <TextField
                className="mt-2"
                label="Numero ospiti"
                value={reservation?.guests}
                onChange={(e) =>
                  dispatch(setReservationGuests(parseInt(e.target.value) || 0))
                }
                variant={'filled'}
                error={
                  reservation.table &&
                  reservation.guests > reservation.table?.capacity
                }
                fullWidth
              />

              {reservation.id && (
                <FormControl className="mt-2" variant={'filled'} fullWidth>
                  <InputLabel id="table-select">Tavolo</InputLabel>
                  <Select
                    value={reservation?.table?.id}
                    labelId="table-select"
                    label="Tavolo"
                    variant={'filled'}
                    onChange={(e) =>
                      dispatch(
                        setReservationTable(
                          map!.tables.find((t) => t.id === e.target.value)!,
                        ),
                      )
                    }
                  >
                    {map?.tables
                      ?.filter((t) => t.isActive)
                      .map((table, i) => (
                        <MenuItem key={i} value={table.id}>
                          Tav. {table.humanReadableCode}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <Typography variant="h6" component="h3" className="mt-2">
                Cliente
              </Typography>

              <>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  className="mt-2"
                  options={customers}
                  value={reservation?.customer}
                  onChange={(e: any, value) =>
                    selectExistingCustomer(value as Customer)
                  }
                  getOptionLabel={(option) =>
                    `${option.firstName ? option.firstName : ''} ${
                      option.lastName ? option.lastName : ''
                    } ${option.phone ? `(${option.phone})` : ''}`
                  }
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.firstName ? option.firstName : ''}{' '}
                      {option.lastName ? option.lastName : ''}{' '}
                      {option.phone ? option.phone : ''}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={autocompleteInputValue}
                      onChange={(e) =>
                        setAutocompleteInputValue(e.target.value)
                      }
                      label="Cerca cliente esistente"
                      variant="filled"
                      fullWidth
                    />
                  )}
                  disabled={isLoadingCustomers}
                />
              </>

              <>
                <TextField
                  className="mt-2"
                  label="Nome"
                  value={reservation?.customer?.firstName || ''}
                  onChange={(e) =>
                    dispatch(
                      setReservationCustomerValue('firstName', e.target.value),
                    )
                  }
                  variant={'filled'}
                  disabled={typeof reservation?.customer?.id === 'string'}
                  fullWidth
                />

                <TextField
                  className="mt-2"
                  label="Cognome"
                  value={reservation?.customer?.lastName || ''}
                  onChange={(e) =>
                    dispatch(
                      setReservationCustomerValue('lastName', e.target.value),
                    )
                  }
                  variant={'filled'}
                  disabled={typeof reservation?.customer?.id === 'string'}
                  fullWidth
                />

                <TextField
                  className="mt-2"
                  label="Email"
                  value={reservation?.customer?.email || ''}
                  onChange={(e) =>
                    dispatch(
                      setReservationCustomerValue('email', e.target.value),
                    )
                  }
                  variant={'filled'}
                  disabled={typeof reservation?.customer?.id === 'string'}
                  fullWidth
                />

                <TextField
                  className="mt-2"
                  label="Telefono"
                  value={reservation?.customer?.phone || ''}
                  onChange={(e) =>
                    dispatch(
                      setReservationCustomerValue('phone', e.target.value),
                    )
                  }
                  variant={'filled'}
                  disabled={typeof reservation?.customer?.id === 'string'}
                  fullWidth
                />

                <TextField
                  className="mt-2"
                  label="Referral"
                  value={reservation?.referral || ''}
                  onChange={(e) =>
                    dispatch(setReservationReferral(e.target.value))
                  }
                  variant={'filled'}
                  fullWidth
                />

                <TextField
                  className="mt-2"
                  label="Note"
                  value={reservation?.notes || ''}
                  onChange={(e) =>
                    dispatch(setReservationNotes(e.target.value))
                  }
                  variant={'filled'}
                  rows={3}
                  multiline
                  fullWidth
                />
              </>

              <Button
                className="mt-2"
                variant="contained"
                color="primary"
                onClick={() =>
                  reservation.id
                    ? dispatch(editReservation())
                    : dispatch(saveReservation())
                }
                fullWidth
              >
                {reservation.id ? 'Modifica' : 'Crea'} prenotazione
              </Button>

              {reservation.id && (
                <Button
                  variant="outlined"
                  className="mt-1"
                  color="error"
                  onClick={() =>
                    dispatch(
                      askForConfirmation({
                        message:
                          'Sei sicuro di voler eliminare la prenotazione?',
                        onConfirm: () => dispatch(removeReservation()),
                      }),
                    )
                  }
                  fullWidth
                >
                  Cancella prenotazione
                </Button>
              )}

              <Button
                variant="outlined"
                className="mt-1"
                color="secondary"
                fullWidth
                onClick={() => dispatch(deselectTableAndGoBack())}
              >
                Chiudi
              </Button>
            </div>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};
