import React, { useEffect } from 'react';
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  customerSelector,
  setIsCreatingCustomer,
  setNewCustomerValue,
} from '../../features/customer.feature';
import { addCustomer } from '../../features/customer.feature';
import {
  close,
  confirmationModalSelector,
} from '../../features/confirmations.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  p: 4,
  padding: '2em 2em 4em',
};

export const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const { isOpen, onConfirm, message } = useSelector(confirmationModalSelector);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={() => dispatch(close())}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <>
        <Fade in={isOpen}>
          <div className="container">
            <Box sx={style}>
              <h2 className="font-bold text-2xl">⚠️ Attenzione!</h2>

              <div className="w-100 mt-1">
                <p>{message}</p>

                <div className="text-right">
                  <Button
                    color="secondary"
                    className="mt-2 mr-1"
                    onClick={() => dispatch(close())}
                  >
                    Annulla
                  </Button>

                  <Button
                    className="mt-2"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      dispatch(close());
                      onConfirm?.();
                    }}
                  >
                    Conferma
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Fade>
      </>
    </Modal>
  );
};
