import React, { useEffect } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Table, TableOrientation, TableShape } from '../../../types/table';
import {
  setSelectedTable,
  setSelectedTableIsNew,
} from '../../features/map.feature';
import { setIsCreatingNewMap } from '../../features/maps.feature';

const style = {
  position: 'absolute' as 'absolute',
  bottom: 0,
  left: '50%',
  height: '85vh',
  transform: 'translate(-50%, 0)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px 16px 0 0',
  maxHeight: '90vh',
  overflowY: 'scroll',
  p: 4,
};

export const TableEditor = ({
  table,
  onUpdate,
  onCreate,
  onDelete,
  isNew,
}: {
  table?: Table;
  onUpdate: (table: Table) => void;
  onCreate: (table: Table) => void;
  onDelete: () => void;
  isNew: boolean;
}) => {
  const dispatch = useDispatch();
  const [cachedTable, setCachedTable] = React.useState<Table | undefined>(
    table,
  );

  useEffect(() => {
    setCachedTable(table);
  }, [table]);

  const onTableUpdate = (
    key: 'humanReadableCode' | 'capacity' | 'orientation' | 'shape',
    value: number | string,
  ) => {
    setCachedTable({
      ...cachedTable!,
      [key]: value,
    });
  };

  const onConfirm = () => {
    if (isNew) {
      onCreate(cachedTable!);
    } else {
      onUpdate(cachedTable!);
    }
    setCachedTable(undefined);
    dispatch(setSelectedTable(undefined));
    dispatch(setIsCreatingNewMap(false));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={table !== undefined}
      onClose={() => {
        dispatch(setSelectedTable(undefined));
        dispatch(setSelectedTableIsNew(false));
      }}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={table !== undefined}>
        <div className="container">
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Tavolo {cachedTable?.humanReadableCode}
            </Typography>
            <div className="row">
              <div className="col-12 mt-2">
                <TextField
                  label="Codice tavolo"
                  value={String(cachedTable?.humanReadableCode)}
                  onChange={(e) =>
                    onTableUpdate('humanReadableCode', e.target.value)
                  }
                  variant="filled"
                  fullWidth
                />
              </div>

              <div className="col-12 mt-2">
                <TextField
                  label="Posti"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={String(cachedTable?.capacity)}
                  onChange={(e) =>
                    onTableUpdate('capacity', parseInt(e.target.value) || 0)
                  }
                  variant="filled"
                  fullWidth
                />
              </div>

              <div className="col-12 mt-2">
                <ToggleButtonGroup
                  color="primary"
                  value={cachedTable?.orientation}
                  exclusive
                  onChange={(e, v) => onTableUpdate('orientation', v)}
                  aria-label="Orientamento"
                  fullWidth
                >
                  <ToggleButton value={TableOrientation.VERTICAL}>
                    Verticale
                  </ToggleButton>
                  <ToggleButton value={TableOrientation.HORIZONTAL}>
                    Orizzontale
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div className="col-12 mt-2">
                <ToggleButtonGroup
                  color="primary"
                  value={cachedTable?.shape}
                  exclusive
                  onChange={(e, v) => onTableUpdate('shape', v)}
                  aria-label="Forma"
                  fullWidth
                >
                  <ToggleButton value={TableShape.RECTANGLE}>
                    Rettangolare
                  </ToggleButton>
                  <ToggleButton value={TableShape.CIRCLE}>
                    Circolare
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="row mt-2">
              <Button
                variant="contained"
                color="primary"
                onClick={onConfirm}
                fullWidth
              >
                OK
              </Button>
              <Button
                className="mt-1"
                color={'secondary'}
                onClick={() => {
                  dispatch(setSelectedTable(undefined));
                  dispatch(setSelectedTableIsNew(false));
                }}
                fullWidth
              >
                Annulla
              </Button>
              {!isNew && (
                <Button
                  className="mt-1"
                  color={'error'}
                  onClick={onDelete}
                  fullWidth
                >
                  Elimina
                </Button>
              )}
            </div>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};
